<template>
    <main :class="{'theme-perfect': theme == 'perfect'}">
        <Navbar />
        <div class="main">
            <div class="container">
                <template v-if="pipe == 3">
                    <div class="btn-group w-100" role="group" :class="{'text-nowrap': platform == 'mobile'}">
                        <template v-if="platform == 'desktop'">
                            <div class="dropdown d-flex">
                                <button type="button" class="btn btn-secondary dropdown-toggle" id="dropdown-menu-salesman" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" :class="{'active': user_type == 1, 'btn-block rounded text-left': platform == 'mobile'}">
                                    <i class="fas fa-user-tag mr-2"></i>
                                    <span>Vendedores</span>
                                    <span class="badge badge-warning float-right mt-1" v-show="user_type == 1 && users.length > 0">{{users.length}}</span>
                                    <div class="dropdown-menu" aria-labelledby="dropdown-menu-salesman">
                                        <a class="dropdown-item" href="javascript:;" @click="usersListStatus(1, 1)" :class="{'active': user_type == 1 && type == 1}"><i class="fas fa-user-check mr-2"></i>Ativo</a>
                                        <a class="dropdown-item" href="javascript:;" @click="usersListStatus(2, 1)" :class="{'active': user_type == 1 && type == 2}"><i class="fas fa-user-lock mr-2"></i>Bloqueado</a>
                                        <a class="dropdown-item" href="javascript:;" @click="userList(2)" :class="{'active': user_type == 1 && show_buttons}"><i class="fas fa-user-friends mr-2"></i>Por gerente</a>
                                    </div>
                                </button>
                            </div>
                        </template>
                        <template v-else>
                            <button type="button" class="btn btn-secondary" @click="selectTypeUsers(1)" :class="{'active': user_type == 1, 'mr-3 rounded': platform == 'mobile'}">
                                <div class="d-flex align-items-center justify-content-between">
                                    <i class="fas fa-user-tag mr-2"></i>
                                    <span>Vendedores</span>
                                    <span class="badge badge-warning mt-1 ml-2" v-show="user_type == 1 && users.length > 0">{{users.length}}</span>
                                </div>
                            </button>
                        </template>
                        <template v-if="platform == 'desktop'">
                           <div class="dropdown d-flex">
                                <button type="button" class="btn btn-secondary dropdown-toggle" id="dropdown-menu-manager" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" :class="{'active': user_type == 2, 'btn-block rounded text-left': platform == 'mobile'}">
                                    <i class="fas fa-user-friends mr-2"></i>
                                    <span>Gerentes</span>
                                    <span class="badge badge-warning float-right mt-1" v-show="user_type == 2 && users.length > 0">{{users.length}}</span>
                                    <div class="dropdown-menu" aria-labelledby="dropdown-menu-manager">
                                        <a class="dropdown-item" href="javascript:;" @click="usersListStatus(1, 2)" :class="{'active': user_type == 2 && type == 1}"><i class="fas fa-user-check mr-2"></i>Ativo</a>
                                        <a class="dropdown-item" href="javascript:;" @click="usersListStatus(2, 2)" :class="{'active': user_type == 2 && type == 2}"><i class="fas fa-user-lock mr-2"></i>Bloqueado</a>
                                        <a class="dropdown-item" href="javascript:;" @click="userList(5)" :class="{'active': user_type == 2 && show_buttons}"><i class="fas fa-user-friends mr-2"></i>Por supervisor</a>
                                    </div>
                                </button>
                            </div>
                        </template>
                        <template v-else>
                            <button type="button" class="btn btn-secondary" @click="selectTypeUsers(2)" :class="{'active': user_type == 2, 'mr-3 rounded': platform == 'mobile'}">
                                <template v-if="platform == 'desktop'">
                                    <i class="fas fa-user-friends mr-2"></i>
                                    <span>Gerentes</span>
                                    <span class="badge badge-warning float-right mt-1" v-show="user_type == 2 && users.length > 0">{{users.length}}</span>
                                </template>
                                <template v-else>
                                    <div class="d-flex align-items-center justify-content-between">
                                        <i class="fas fa-user-friends mr-2"></i>
                                        <span>Gerentes</span>
                                        <span class="badge badge-warning mt-1 ml-2" v-show="user_type == 2 && users.length > 0">{{users.length}}</span>
                                    </div>
                                </template>
                            </button>
                        </template>
                        <button type="button" class="btn btn-secondary" @click="usersList(3)" :class="{'active': user_type == 3, 'mr-3 rounded': platform == 'mobile'}" :disabled="user_type == 3">
                            <template v-if="platform == 'desktop'">
                                <i class="fas fa-user-shield mr-2"></i>
                                <span>Admins</span>
                                <span class="badge badge-warning float-right mt-1" v-show="user_type == 3 && users.length > 0">{{users.length}}</span>
                            </template>
                            <template v-else>
                                <div class="d-flex align-items-center justify-content-between">
                                    <i class="fas fa-user-shield mr-2"></i>
                                    <span>Admins</span>
                                    <span class="badge badge-warning mt-1 ml-2" v-show="user_type == 3 && users.length > 0">{{users.length}}</span>
                                </div>
                            </template>
                        </button>
                        <button type="button" class="btn btn-secondary" @click="usersList(5)" :class="{'active': user_type == 5, 'mr-3 rounded': platform == 'mobile'}" :disabled="user_type == 5">
                            <template v-if="platform == 'desktop'">
                                <i class="fas fa-users-crown mr-2"></i>
                                <span>Supervisores</span>
                                <span class="badge badge-warning float-right mt-1" v-show="user_type == 5 && users.length > 0">{{users.length}}</span>
                            </template>
                            <template v-else>
                                <div class="d-flex align-items-center justify-content-between">
                                    <i class="fas fa-users-crown mr-2"></i>
                                    <span>Supervisores</span>
                                    <span class="badge badge-warning mt-1 ml-2" v-show="user_type == 5 && users.length > 0">{{users.length}}</span>
                                </div>
                            </template>
                        </button>
                        <button type="button" class="btn btn-secondary" @click="usersList(4)" :class="{'active': user_type == 4, 'rounded': platform == 'mobile'}" :disabled="user_type == 4">
                            <template v-if="platform == 'desktop'">
                                <i class="fas fa-users mr-2"></i>
                                <span>Clientes</span>
                                <span class="badge badge-warning float-right mt-1" v-show="user_type == 4 && users.length > 0">{{users.length}}</span>
                            </template>
                            <template v-else>
                                <div class="d-flex align-items-center justify-content-between">
                                    <i class="fas fa-users mr-2"></i>
                                    <span>Clientes</span>
                                    <span class="badge badge-warning mt-1 ml-2" v-show="user_type == 4 && users.length > 0">{{users.length}}</span>
                                </div>
                            </template>
                        </button>
                    </div><!-- /btn-group -->                    
                </template>
                <template v-else-if="pipe == 5">
                    <div class="btn-group w-100" role="group" :class="{'text-nowrap': platform == 'mobile'}">
                        <template v-if="platform == 'desktop'">
                            <div class="dropdown d-flex">
                                <button type="button" class="btn btn-secondary dropdown-toggle" id="dropdown-menu-salesman" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" :class="{'active': user_type == 1, 'btn-block rounded text-left': platform == 'mobile'}">
                                    <i class="fas fa-user-tag mr-2"></i>
                                    <span>Vendedores</span>
                                    <span class="badge badge-warning float-right mt-1" v-show="user_type == 1 && users.length > 0">{{users.length}}</span>
                                    <div class="dropdown-menu" aria-labelledby="dropdown-menu-salesman">
                                        <a class="dropdown-item" href="javascript:;" @click="usersListStatus(1, 1)" :class="{'active': user_type == 1 && type == 1}"><i class="fas fa-user-check mr-2"></i>Ativo</a>
                                        <a class="dropdown-item" href="javascript:;" @click="usersListStatus(2, 1)" :class="{'active': user_type == 1 && type == 2}"><i class="fas fa-user-lock mr-2"></i>Bloqueado</a>
                                        <a class="dropdown-item" href="javascript:;" @click="userList(2)" :class="{'active': user_type == 1 && show_buttons}"><i class="fas fa-user-friends mr-2"></i>Por gerente</a>
                                    </div>
                                </button>
                            </div>
                        </template>
                        <template v-else>
                            <button type="button" class="btn btn-secondary" @click="selectTypeUsers(1)" :class="{'active': user_type == 1, 'mr-3 rounded': platform == 'mobile'}">
                                <div class="d-flex align-items-center justify-content-between">
                                    <i class="fas fa-user-tag mr-2"></i>
                                    <span>Vendedores</span>
                                    <span class="badge badge-warning mt-1 ml-2" v-show="user_type == 1 && users.length > 0">{{users.length}}</span>
                                </div>
                            </button>
                        </template>
                        <template v-if="platform == 'desktop'">
                           <div class="dropdown d-flex">
                                <button type="button" class="btn btn-secondary dropdown-toggle" id="dropdown-menu-manager" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" :class="{'active': user_type == 2, 'btn-block rounded text-left': platform == 'mobile'}">
                                    <i class="fas fa-user-friends mr-2"></i>
                                    <span>Gerentes</span>
                                    <span class="badge badge-warning float-right mt-1" v-show="user_type == 2 && users.length > 0">{{users.length}}</span>
                                    <div class="dropdown-menu" aria-labelledby="dropdown-menu-manager">
                                        <a class="dropdown-item" href="javascript:;" @click="usersListStatus(1, 2)" :class="{'active': user_type == 2 && type == 1}"><i class="fas fa-user-check mr-2"></i>Ativo</a>
                                        <a class="dropdown-item" href="javascript:;" @click="usersListStatus(2, 2)" :class="{'active': user_type == 2 && type == 2}"><i class="fas fa-user-lock mr-2"></i>Bloqueado</a>
                                    </div>
                                </button>
                            </div>
                        </template>
                        <template v-else>
                            <button type="button" class="btn btn-secondary" @click="selectTypeUsers(2)" :class="{'active': user_type == 2}">
                                <template v-if="platform == 'desktop'">
                                    <i class="fas fa-user-friends mr-2"></i>
                                    <span>Gerentes</span>
                                    <span class="badge badge-warning float-right mt-1" v-show="user_type == 2 && users.length > 0">{{users.length}}</span>
                                </template>
                                <template v-else>
                                    <div class="d-flex align-items-center justify-content-between">
                                        <i class="fas fa-user-friends mr-2"></i>
                                        <span>Gerentes</span>
                                        <span class="badge badge-warning mt-1 ml-2" v-show="user_type == 2 && users.length > 0">{{users.length}}</span>
                                    </div>
                                </template>
                            </button>
                        </template>
                    </div>
                </template>
               <div class="mt-3 mb-3">
                   <div v-show="loading == true" class="alert alert-warning" role="alert">
                        <div class="alert-heading text-center mb-0">
                            <div class="spinner-border mr-2 align-middle" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                            <span>Carregando, aguarde...</span>
                        </div>
                    </div><!-- /alert-warning -->
                    <div v-show="error.length > 0" class="alert alert-danger" role="alert">
                        <h4 class="alert-heading">Ops, ocorreu um problema!</h4>
                        <hr />
                        <p class="mb-0">{{error}}</p>
                    </div><!-- /alert-danger -->
                    <div v-show="users.length == 0 && nothing_found == true" class="alert alert-info" role="alert">
                        <h4 class="alert-heading">Ops, ocorreu um problema!</h4>
                        <hr />
                        <p class="mb-0">Não encontramos nenhum {{user_type == 1 ? 'vendedor' : user_type == 2 ? 'gerente' : user_type == 3 ? 'administrador' : user_type == 4 ? 'cliente' : 'supervisor'}}!</p>
                    </div><!-- /alert-danger -->
                   <div v-show="users.length > 0 && nothing_found == false">
                       <div class="row ml-0 mb-3 mr-0">
                           <div class="col-auto pl-0" v-if="pipe == 3" :class="{'d-contents': platform == 'mobile' && user_type == 1, 'd-none': user_type > 1}">
                                <div class="btn-group w-100" :class="{'mb-3': platform == 'mobile'}" role="group" aria-label="Basic example">
                                    <template v-if="show_buttons">
                                        <button type="button" class="btn btn-secondary" :class="{'active': type == '', 'text-truncate': platform == 'mobile'}" @click="type = ''"><i class="fas fa-list mr-2" v-show="platform == 'desktop'"></i>Todos</button>
                                        <button type="button" class="btn btn-secondary" :class="{'active': type == 1, 'text-truncate': platform == 'mobile'}" @click="type = 1"><i class="fas fa-user-check mr-2" v-show="platform == 'desktop'"></i>Ativo</button>
                                        <button type="button" class="btn btn-secondary" :class="{'active': type == 2, 'text-truncate': platform == 'mobile'}" @click="type = 2"><i class="fas fa-user-lock mr-2" v-show="platform == 'desktop'"></i>Bloqueado</button>
                                    </template>
                                    <button type="button" class="btn btn-warning" :class="{'active': type == 3, 'text-truncate': platform == 'mobile'}" @click="type = 3" v-if="user_type == 1 && pipe == 3">
                                        <i class="fas fa-user-alt-slash mr-2"></i>Ausente
                                    </button>
                                </div>
                            </div><!-- /col --> 
                            <div class="col pl-0" :class="{'d-contents pr-0': platform == 'mobile'}">
                                <div id="virtual-select"></div>
                            </div><!-- /col -->
                            <div class="col pl-0 pr-0" v-if="platform == 'desktop'">
                                <input class="form-control" type="text" v-model="search" placeholder="Buscar usuário...">
                            </div><!-- /col -->
                        </div><!-- /row -->
                        <template v-if="filter_absent && !filter_absent_cancel && users_absent.length">
                            <div class="alert alert-warning text-center" role="alert">
                                <h4 class="alert-heading">Atenção!</h4>
                                <p>Agora é possível excluir de uma só vez, vendedores que estão há mais de 30 dias sem fazer apostas. <br /> Você tem nesse momento (<strong>{{users_absent.length}}</strong>) {{users_absent.length > 1 ? 'vendedores que estão' : 'vendedor que está'}} há mais de 30 dias ausentes.</p>
                                <hr>
                                <p class="mb-0">
                                    <button class="btn btn-danger mr-2" @click="deleteUsersAbsent">Excluir</button>
                                    <button class="btn btn-link" @click="filter_absent_cancel = true">Fechar</button>
                                </p>
                            </div>
                        </template>
                        <template v-if="listUsers.length">
                            <collection-cluster v-bind="collection" :items="listUsers" :style="{height: screen_innerHeight + 'px'}" :class="{'desktop': platform == 'desktop', 'pr-3': platform == 'desktop' && (user_type == 1 || user_type == 2 || user_type == 4 || user_type == 5 && listUsers.length >= 1)}">
                                <div class="mb-3" slot="item" slot-scope="{ cell, item }" :key="cell.index" :ref="item.id">
                                    <div class="card mb-3">
                                        <div class="card-header">
                                            <div class="row">
                                                <div class="col text-truncate mt-1">{{item.nome}}</div>
                                                <div class="col-auto" :class="{'pr-0': user_type == 1}" v-if="user_type == 1 || user_type == 4">
                                                    <button class="btn btn-block btn-sm btn-info pl-3 pr-3" @click="viewInfo(item.geolocation)">
                                                        <i class="fas fa-map-marker-alt"></i>
                                                    </button>
                                                </div>
                                                <div class="col-auto" v-if="(user_type == 1 || user_type == 2) && pipe == 3">
                                                    <template v-if="user_type == 1">
                                                        <button class="btn btn-block btn-sm btn-secondary" @click="addManager(item.id)">
                                                            <template v-if="platform == 'desktop'">
                                                                <i class="fas fa-user-friends mr-2"></i>Gerente
                                                            </template>
                                                            <template v-else>
                                                                <i class="fas fa-user-friends"></i>
                                                            </template>
                                                        </button>
                                                    </template>
                                                    <template v-else>
                                                        <button class="btn btn-block btn-sm btn-secondary" @click="addSupervisor(item.id)">
                                                            <template v-if="platform == 'desktop'">
                                                                <i class="fas fas fa-users-crown mr-2 mr-2"></i>Supervisor
                                                            </template>
                                                            <template v-else>
                                                                <i class="fas fas fa-users-crown"></i>
                                                            </template>
                                                        </button>
                                                    </template>
                                                </div>                                    
                                            </div>
                                        </div>
                                        <div class="card-body">
                                            <div class="row border-bottom pb-2">
                                                <div class="col-sm text-truncate">Local</div>
                                                <div class="col-sm text-truncate text-muted">{{item.endereco}}</div>
                                            </div><!-- /row -->
                                            <div class="row border-bottom pt-2 pb-2">
                                                <div class="col-sm text-truncate">Usuário</div>
                                                <div class="col-sm text-truncate text-muted">{{item.usuario}}</div>
                                            </div><!-- /row -->
                                            <div class="row border-bottom pt-2 pb-2">
                                                <div class="col-sm text-truncate">Situação</div>
                                                <div class="col-sm text-truncate text-muted">{{item.situacao}}</div>
                                            </div><!-- /row -->
                                            <div class="row border-bottom pt-2" :class="{'border-0': user_type == 3, 'pb-2': user_type != 3}">
                                                <div class="col-sm text-truncate">Telefone</div>
                                                <div class="col-sm text-truncate text-muted">{{item.contato}}</div>
                                            </div><!-- /row -->
                                            <div class="row border-bottom pt-2 pb-2" v-show="user_type == 1 || user_type == 2 || user_type == 4 || user_type == 5">
                                                <div class="col-sm text-truncate">Crédito atual</div>
                                                <div class="col-sm text-truncate text-muted">{{item.credito | formatCoin}}</div>
                                            </div><!-- /row -->
                                            <div class="row border-bottom pt-2 pb-2" v-show="user_type == 1">
                                                <div class="col-sm text-truncate">Crédito simples</div>
                                                <div class="col-sm text-truncate text-muted">{{item.credito_simples | formatCoin}}</div>
                                            </div><!-- /row -->
                                            <div class="row border-bottom pt-2 pb-2" v-show="user_type == 1 || user_type == 2 || user_type == 4 || user_type == 5">
                                                <div class="col-sm text-truncate">Cancelar apostas</div>
                                                <div class="col-sm text-truncate text-muted">{{item.cancelar_aposta == 1 ? 'Sim' : 'Não'}}</div>
                                            </div><!-- /row -->
                                            <div class="row border-bottom pt-2 pb-2" v-show="user_type == 2 || user_type == 5">
                                                <div class="col-sm text-truncate">{{user_type == 2 ? 'Editar vendedor' : 'Editar gerente e vendedor'}}</div>
                                                <template v-if="user_type == 2">
                                                    <div class="col-sm text-truncate text-muted">{{item.permissoes.editar_vendedor == 'sim' ? 'Sim' : 'Não'}}</div>
                                                </template>
                                                <template v-else-if="user_type == 5">
                                                    <div class="col-sm text-truncate text-muted">{{item.permissoes.editar_usuarios == 'sim' ? 'Sim' : 'Não'}}</div>
                                                </template>
                                            </div><!-- /row -->
                                            <div class="row border-bottom pt-2 pb-2" v-show="user_type == 2 || user_type == 5">
                                                <div class="col-sm text-truncate">{{user_type == 2 ? 'Cadastrar vendedor' : 'Cadastrar gerente e vendedor'}}</div>
                                                <template v-if="user_type == 2">
                                                    <div class="col-sm text-truncate text-muted">{{item.permissoes.cadastrar_vendedor == 'sim' ? 'Sim' : 'Não'}}</div>
                                                </template>
                                                <template v-else-if="user_type == 5">
                                                    <div class="col-sm text-truncate text-muted">{{item.permissoes.cadastrar_usuarios == 'sim' ? 'Sim' : 'Não'}}</div>
                                                </template>
                                            </div><!-- /row -->
                                            <div class="row border-bottom pt-2 pb-2" v-show="user_type == 2">
                                                <div class="col-sm text-truncate">Comissão do apurado</div>
                                                <div class="col-sm text-truncate text-muted">{{item.permissoes.comissao_do_apurado == 'sim' ? 'Sim' : 'Não'}}</div>
                                            </div><!-- /row -->
                                            <div class="row border-bottom pt-2 pb-2" v-show="user_type == 2 || user_type == 5">
                                                <div class="col-sm text-truncate">{{user_type == 2 ? 'Liberar crédito para o vendedor' : 'Liberar crédito para gerente e vendedor'}}</div>
                                                <div class="col-sm text-truncate text-muted">{{item.permissoes.liberar_credito == 'sim' ? 'Sim' : 'Não'}}</div>
                                            </div><!-- /row -->
                                            <div class="row pt-2" v-show="user_type == 2 || user_type == 5" :class="{'border-bottom pb-2': user_type == 5}">
                                                <div class="col-sm text-truncate">{{user_type == 2 ? 'Alterar comissões do vendedor' : 'Alterar comissões do gerente e vendedor'}}</div>
                                                <div class="col-sm text-truncate text-muted">{{item.permissoes.alterar_comissoes == 'sim' ? 'Sim' : 'Não'}}</div>
                                            </div><!-- /row -->
                                            <div class="row pt-2" v-show="user_type == 5">
                                                <div class="col-sm text-truncate">Bloquear/Desbloquear gerente e vendedor</div>
                                                <div class="col-sm text-truncate text-muted">{{item.permissoes.bloquear_desbloquer_usuarios == 'sim' ? 'Sim' : 'Não'}}</div>
                                            </div><!-- /row -->
                                            <div class="row border-bottom pt-2 pb-2" v-show="user_type == 1 || user_type == 4">
                                                <div class="col-sm text-truncate">{{ user_type == 1 ? 'Comissão das apostas premiadas' : 'Comissão por indicação (Afiliados)'}}</div>
                                                <div class="col-sm text-truncate text-muted">{{item.comissao_aposta_premiada}}</div>
                                            </div><!-- /row -->
                                            <div class="row border-bottom pt-2 pb-2" v-show="user_type == 1 || user_type == 4">
                                                <div class="col-sm text-truncate">Apostar em jogo ao vivo</div>
                                                <div class="col-sm text-truncate text-muted">{{item.aposta_ao_vivo == 1 ? 'Sim' : 'Não'}}</div>
                                            </div><!-- /row -->
                                            <div class="row border-bottom pt-2 pb-2" v-show="user_type == 1 || user_type == 4">
                                                <div class="col-sm text-truncate">Valor mínimo da aposta</div>
                                                <div class="col-sm text-truncate text-muted">{{item.valor_minimo_aposta}}</div>
                                            </div><!-- /row -->
                                            <div class="row border-bottom pt-2 pb-2" v-show="user_type == 1 || user_type == 4">
                                                <div class="col-sm text-truncate">Valor máximo da aposta</div>
                                                <div class="col-sm text-truncate text-muted">{{item.valor_maximo_aposta}}</div>
                                            </div><!-- /row -->
                                            <div class="row border-bottom pt-2 pb-2" v-show="user_type == 1 || user_type == 4">
                                                <div class="col-sm text-truncate">Mínimo de jogos por aposta</div>
                                                <div class="col-sm text-truncate text-muted">{{item.minimo_de_jogos_por_aposta}}</div>
                                            </div><!-- /row -->
                                            <div class="row border-bottom pt-2 pb-2" v-show="user_type == 1 || user_type == 4">
                                                <div class="col-sm" :class="{'text-truncate': platform == 'desktop'}">Aposta odd mínima um palpite (Pré-jogo)</div>
                                                <div class="col-sm text-truncate text-muted">{{item.min_valor_odd_um_palpite}}</div>
                                            </div><!-- /row -->
                                            <div class="row border-bottom pt-2 pb-2" v-show="user_type == 1 || user_type == 4">
                                                <div class="col-sm" :class="{'text-truncate': platform == 'desktop'}">Aposta odd mínima dois ou mais palpites (Pré-jogo)</div>
                                                <div class="col-sm text-truncate text-muted">{{item.min_valor_odd_mais_palpites}}</div>
                                            </div><!-- /row -->
                                            <div class="row border-bottom pt-2 pb-2" v-show="user_type == 1 || user_type == 4">
                                                <div class="col-sm" :class="{'text-truncate': platform == 'desktop'}">Aposta odd mínima um palpite (Ao vivo)</div>
                                                <div class="col-sm text-truncate text-muted">{{item.min_valor_odd_um_palpite_ao_vivo}}</div>
                                            </div><!-- /row -->
                                            <div class="row border-bottom pt-2 pb-2" v-show="user_type == 1 || user_type == 4">
                                                <div class="col-sm" :class="{'text-truncate': platform == 'desktop'}">Aposta odd mínima dois ou mais palpites (Ao vivo)</div>
                                                <div class="col-sm text-truncate text-muted">{{item.min_valor_odd_mais_palpites_ao_vivo}}</div>
                                            </div><!-- /row -->
                                            <div class="row border-bottom pt-2 pb-2" v-show="user_type == 1 || user_type == 4">
                                                <div class="col-sm text-truncate">Horário da última aposta feita</div>
                                                <div class="col-sm text-truncate text-muted">{{item.horario_da_ultima_aposta | formatDate}}</div>
                                            </div><!-- /row -->
                                            <div class="row pt-2 mb-2" v-show="user_type == 1 || user_type == 4">
                                                <div class="col-sm text-truncate">Quando a última aposta foi feita</div>
                                                <div class="col-sm text-truncate text-muted">{{item.horario_da_ultima_aposta | fromNowDate}}</div>
                                            </div><!-- /row -->
                                            <div class="row mt-3">
                                                <div class="col-12">
                                                    <div class="w-100" :class="{'btn-group': platform == 'desktop', 'btn-group-vertical': platform == 'mobile'}" role="group">
                                                        <button class="btn btn-danger" :class="{'mb-2 rounded-bottom': platform == 'mobile'}" @click="deleteUser(item.id, item.usuario)">
                                                            <i class="fas fa-user-times mr-2"></i>Excluir
                                                        </button>
                                                        <button class="btn btn-dark" :class="{'mb-2 rounded-top rounded-bottom': platform == 'mobile'}" @click="locksAndUnlocks(item.id, item.ativo, item.usuario)">
                                                            <i class="mr-2" :class="{'fas fa-lock': item.ativo == 1, 'fas fa-unlock': item.ativo == 2}"></i>{{item.ativo == 1 ? 'Bloquear' : 'Desbloquear'}}
                                                        </button>
                                                        <template v-if="item.tipo == 1 || item.tipo == 2 || item.tipo == 4 || item.tipo == 5">
                                                            <button class="btn btn-primary" :class="{'mb-2 rounded-top rounded-bottom': platform == 'mobile'}" @click="changeCredit(item.id, item.usuario, item.tipo)">
                                                                <i class="fas fa-credit-card mr-2"></i>Alterar crédito
                                                            </button>
                                                            <button class="btn btn-warning" :class="{'rounded-top': platform == 'mobile'}" @click="editUser(item.id)">
                                                                <i class="fas fa-user-edit mr-2"></i>Editar usuário
                                                            </button>
                                                        </template>
                                                        <template v-else>
                                                            <button class="btn btn-success" :class="{'rounded-top': platform == 'mobile'}" @click="changePassword(item.id, item.usuario)">
                                                                <i class="fas fa-key mr-2"></i>Alterar senha
                                                            </button>
                                                        </template>
                                                    </div><!-- /btn-group -->
                                                </div><!-- /col -->
                                            </div><!-- /row -->
                                        </div><!-- /card-body -->
                                    </div><!-- /card -->
                                </div>
                            </collection-cluster>
                        </template>
                   </div><!-- /div -->
               </div><!-- /mt-3 mb-3 -->
            </div><!-- /container -->
        </div><!-- /main -->
      <loading :active.sync="bg_loading" :can-cancel="false" :is-full-page="true"></loading>
    </main>
</template>

<script>
import {api} from '../api'
import Navbar from './Navbar'
import Swal from 'sweetalert2'
import moment from 'moment-timezone'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
import 'virtual-select-plugin/dist/virtual-select.min.css'
import 'virtual-select-plugin/dist/virtual-select.min.js'
import CollectionCluster from 'vue-collection-cluster'

const heightAdjustMobile = 260;
const heightAdjustDesktop = 206;
const heightAdjust = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent) ? heightAdjustMobile : heightAdjustDesktop;

export default {
    data() {
        return {
            pipe: 0,
            type: '',
            error: '',
            search: '',
            users: [],
            banks: [],
            user_type: 0,
            users_absent: [],
            loading: false,
            bg_loading: false,
            show_buttons: false,
            nothing_found: false,
            filter_absent: false,
            filter_absent_cancel: false,
            managers_and_supervisores: [],
            collection: {
                heightType: 'automatic',
                // itemHeight: 742
            },
            screen_innerHeight: window.innerHeight - heightAdjust,
            hostname: window.location.hostname.includes('citysports'),
            platform: /iPhone|iPad|iPod|Android/i.test(navigator.userAgent) ? 'mobile' : 'desktop'
        }
    },
    components: {
        Navbar,
        Loading,
        CollectionCluster
    },
    methods: {
        usersList(id) {

            const self = this;

            self.type = '';
            self.error = '';
            self.users = [];
            self.search = '';
            self.user_type = id;
            self.loading = true;
            self.users_absent = [];
            self.nothing_found = false;
            self.filter_absent = false;
            self.managers_and_supervisores = [];

            api.get(`dashboard/users/${id}`).then((response) => {
                switch(response.data.result) {
                    case 'success':
                        self.users = response.data.users.sort((a, b) => {
                            return self.removeAccents(a.nome).localeCompare(self.removeAccents(b.nome), 'fr', {
                                ignorePunctuation: true
                            });
                        });
                    break;
                    case 'nothing_found':
                        self.nothing_found = true;
                    break;
                    default:
                        self.error = 'Ocorreu um erro, tente novamente!';
                    break;
                }
            }).catch((error) => {
                try {
                    self.error = self.network_erros[error.status]['message'];
                } catch(e) {
                    self.error = self.network_erros[408]['message'];
                }
            }).finally(() => {
                self.loading = false;
            });
        },
        usersListStatus(id, type = 1) {

            const self = this;

            self.type = id;
            self.error = '';
            self.users = [];
            self.search = '';
            self.user_type = type;
            self.loading = true;
            self.show_buttons = false;
            self.nothing_found = false;
            self.filter_absent = false;
            self.managers_and_supervisores = [];

            api.get(`dashboard/users/active/${id}/${type}`).then((response) => {
                switch(response.data.result) {
                    case 'success':
                        self.users = response.data.users.sort((a, b) => {
                            return self.removeAccents(a.nome).localeCompare(self.removeAccents(b.nome), 'fr', {
                                ignorePunctuation: true
                            });
                        });
                    break;
                    case 'nothing_found':
                        self.nothing_found = true;
                    break;
                    default:
                        self.error = 'Ocorreu um erro, tente novamente!';
                    break;
                }
            }).catch((error) => {
                try {
                    self.error = self.network_erros[error.status]['message'];
                } catch(e) {
                    self.error = self.network_erros[408]['message'];
                }
            }).finally(() => {
                self.loading = false;
            });
        },
        userList(type) {

            const self = this;

            self.type = '';
            self.users = [];
            self.bg_loading = true;
            self.show_buttons = false;
            self.managers_and_supervisores = [];

            api.get(`dashboard/users/${type}`).then((response) => {
                switch(response.data.result) {
                    case 'success':
                        self.managers_and_supervisores = response.data.users.sort((a, b) => {
                            return self.removeAccents(a.nome).localeCompare(self.removeAccents(b.nome), 'fr', {
                                ignorePunctuation: true
                            });
                        });
                        self.mountList(type);
                    break;
                    case 'nothing_found':
                        Swal.fire(
                            'Atenção!',
                            `Nenhum ${type == 2 ? 'gerente' : 'supervisor'} encontrado!`,
                            'warning'
                        );
                    break;
                    default:
                        Swal.fire(
                            'Atenção!',
                            'Ocorreu um erro, tente novamente!',
                            'warning'
                        );
                    break;
                }
            }).catch((error) => {
                try {
                    Swal.fire(
                        'Falha!',
                        self.network_erros[error.status]['message'],
                        'warning'
                    );
                } catch(e) {
                    Swal.fire(
                        'Falha!',
                        self.network_erros[408]['message'],
                        'warning'
                    );
                }
            }).finally(() => {
                self.bg_loading = false;
            });
        },
        async mountList(type) {
            
            const self = this;
            const userType = type == 2 ? 'gerente' : 'supervisor';

            const { value: formValues } = await Swal.fire({
                title: type == 2 ? 'Gerentes' : 'Supervisores',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                cancelButtonText: 'Cancelar',
                confirmButtonText: 'Finalizar',
                html: `
                    <label class="d-block text-left mb-0">Escolha o ${userType}</label>
                    <input type="hidden" id="swal-input1" value="" />
                    <div id="swal-virtual-select" data-style="swall"></div>
                    <div id="swal-append"></div>
                `,
                customClass: {
                    actions: 'swal2-actions swal-virtual-select'
                },
                focusConfirm: false,
                preConfirm: () => {
                    return {
                        id: document.getElementById('swal-input1').value,
                    }
                },
                onOpen: () => {

                    const options = [];
                    const users = self.managers_and_supervisores.sort((a, b) => {
                        return a.nome.localeCompare(b.nome);
                    });

                    const total_users = users.length;

                    if (total_users) {
                        for (const user of users) {
                            options.push({
                                value: user.id,
                                label: user.nome,
                                description: user.endereco
                            });
                        }
                    }

                    window.VirtualSelect.init({
                        ele: '#swal-virtual-select',
                        search: true,
                        placeholder: `Selecione um ${userType}`,
                        noOptionsText: 'Nenhuma opção disponível',
                        noSearchResultsText: 'Nenhum resultado encontrado',
                        searchPlaceholderText: `Buscar ${userType} (${total_users})...`,
                        hasOptionDescription: true,
                        options: options
                    });

                    if (document.querySelector('#swal-virtual-select')) {
                        document.querySelector('#swal-virtual-select').addEventListener('change', function() {
                            document.getElementById('swal-input1').value = this.value;
                        });
                    }
                }
            });

            if (formValues instanceof Object) {

                if (formValues.id == '') return;

                self.error = '';
                self.users = [];
                self.search = '';
                self.loading = true;
                self.show_buttons = false;
                self.nothing_found = false;
                self.user_type = type == 2 ? 1 : 2;
                self.managers_and_supervisores = [];

                const url_type = type == 2 ? 'manager' : 'supervisor';

                api.get(`dashboard/users/${self.user_type}/by/${url_type}/${formValues.id}`).then((response) => {
                    switch(response.data.result) {
                        case 'success':
                            self.show_buttons = true;
                            self.users = response.data.users.sort((a, b) => {
                                return self.removeAccents(a.nome).localeCompare(self.removeAccents(b.nome), 'fr', {
                                    ignorePunctuation: true
                                });
                            });
                        break;
                        case 'nothing_found':
                            self.nothing_found = true;
                        break;
                        default:
                            self.error = 'Ocorreu um erro, tente novamente!';
                        break;
                    }
                }).catch((error) => {
                    try {
                        self.error = self.network_erros[error.status]['message'];
                    } catch(e) {
                        self.error = self.network_erros[408]['message'];
                    }
                }).finally(() => {
                    self.loading = false;
                });
            }
        },
        deleteUser(id, username) {

            try {

                const self = this;

                Swal.fire({
                    title: 'Excluir usuário',
                    html: `Você deseja mesmo excluir o "<b>${username}</b>" ?`,
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    cancelButtonText: 'Cancelar',
                    confirmButtonText: 'Sim'
                }).then(function(result) {

                    if (result.value) {

                        self.bg_loading = true;

                        api.delete(`dashboard/delete-user/${id}`).then((response) => {
                            switch(response.data.result) {
                                case 'success':

                                    self.removeUserByList(id);

                                    Swal.fire(
                                        'Parabéns!',
                                        'Usuário excluído com sucesso!',
                                        'success'
                                    );
                                break;
                                default:
                                    Swal.fire(
                                        'Falha',
                                        response.data.message,
                                        'error'
                                    );
                            }
                        }).catch((error) => {
                            try {
                                Swal.fire(
                                    'Falha',
                                    self.network_erros[error.status]['message'],
                                    'error'
                                );
                            } catch(e) {
                                Swal.fire(
                                    'Falha',
                                    self.network_erros[408]['message'],
                                    'error'
                                );
                            }
                        }).finally(() => {
                            self.bg_loading = false;
                        });
                    }
                });
            } 
            catch(e) 
            {
                Swal.fire(
                    'Atenção',
                    e.message,
                    'error'
                );
            }
        },
        removeUserByList(id) {
            try {
                const index = this.users.findIndex((item) => item.id == id);
                if (index != -1) {
                    this.users.splice(index, 1);
                }
            } catch(e) {
                // window.console.log(e.message);
            }
        },
        locksAndUnlocks(id, type, username) {

            const self = this;
            const action = type == 1 ? 2 : 1;
            const label = type == 1 ? 'Bloquear' : 'Desbloquear';
            const server_url = self.user_type == 2 ? `dashboard/lock-unlock-user/${id}/${action}/manager` : `dashboard/lock-unlock-user/${id}/${action}`;

            Swal.fire({
				title: `${label} usuário`,
				html: `Você deseja mesmo ${label.toLowerCase()} o <b>"${username}"</b> ?`,
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				cancelButtonText: 'Cancelar',
				confirmButtonText: 'Sim'
			}).then(function(result) {

                if (result.value) {

                    self.bg_loading = true;

                    api.put(server_url).then((response) => {
                        switch(response.data.result) {
                            case 'success':
                                self.updateArrUsers(id, action);

                                Swal.fire(
                                    'Parabéns!',
                                    `Usuário ${type == 1 ? 'bloqueado' : 'desbloqueado'} com sucesso!`,
                                    'success'
                                );
                            break;
                            default:
                                Swal.fire(
                                    'Falha',
                                    response.data.message,
                                    'error'
                                );
                        }
                    }).catch((error) => {
                        try {
                            Swal.fire(
                                'Falha',
                                self.network_erros[error.status]['message'],
                                'error'
                            );
                        } catch(e) {
                            Swal.fire(
                                'Falha',
                                self.network_erros[408]['message'],
                                'error'
                            );
                        }
                    }).finally(() => {
                        self.bg_loading = false;
                    });
                }
            });
        },
        async changeCredit(id, username, tipo) {

            const self = this;
            const isNumeric = /^[0-9.,]+$/;

            if (tipo == 1) {

                const { value: formCredit } = await Swal.fire({
                    title: 'Tipo de crédito',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    cancelButtonText: 'Cancelar',
                    confirmButtonText: 'Alterar',
                    html: `
                        <select id="swal-input1" class="swal2-input w-100">
                            <option value="1" selected>Crédito atual</option>
                            <option value="2">Crédito simples</option>
                        </select>
                    `,
                    focusConfirm: false,
                    preConfirm: () => {
                        return {
                            credit_type: document.getElementById('swal-input1').value
                        }
                    }
                });

                if (formCredit instanceof Object) {
                    
                    const { value: credit } = await Swal.fire({
                        title: 'Alterar crédito',
                        html: `Você está alterando o crédito do <b>"${username}"</b>.`,
                        input: 'number',
                        showCancelButton: true,
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#d33',
                        cancelButtonText: 'Cancelar',
                        customClass: 'sweet-only-custom',
                        inputPlaceholder: 'Informe o crédito',
                        confirmButtonText: 'Alterar',
                        inputValidator: (value) => {

                            if (!value) {
                                return 'Informe algum valor!'
                            } else if (!isNumeric.test(value)) {
                                return 'Informe apenas números!'
                            }
                        }
                    });

                    if (credit) {
                        
                        self.bg_loading = true;

                        api.put(`dashboard/change-credit/${id}/${credit}/${tipo}/credit-type/${formCredit.credit_type}`).then((response) => {
                            switch(response.data.result) {
                                case 'success':
                                    self.updateArrUsersCredit(id, credit, formCredit.credit_type);
                                    Swal.fire(
                                        'Parabéns!',
                                        'O crédito foi alterado com sucesso!',
                                        'success'
                                    )
                                break;
                                default:
                                    Swal.fire(
                                        'Falha',
                                        response.data.message,
                                        'error'
                                    )
                            }
                        }).catch((error) => {
                            try {
                                Swal.fire(
                                    'Falha',
                                    self.network_erros[error.status]['message'],
                                    'error'
                                );
                            } catch(e) {
                                Swal.fire(
                                    'Falha',
                                    self.network_erros[408]['message'],
                                    'error'
                                );
                            }
                        }).finally(() => {
                            self.bg_loading = false;
                        });
                    }   
                }
            } 
            else 
            {
                const { value: credit } = await Swal.fire({
                    title: 'Alterar crédito',
                    html: `Você está alterando o crédito do <b>"${username}"</b>.`,
                    input: 'number',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    cancelButtonText: 'Cancelar',
                    customClass: 'sweet-only-custom',
                    inputPlaceholder: 'Informe o crédito',
                    confirmButtonText: 'Alterar',
                    inputValidator: (value) => {

                        if (!value) {
                            return 'Informe algum valor!'
                        } else if (!isNumeric.test(value)) {
                            return 'Informe apenas números!'
                        }
                    }
                });

                if (credit) {
                    
                    self.bg_loading = true;

                    api.put(`dashboard/change-credit/${id}/${credit}/${tipo}/credit-type/1`).then((response) => {
                        switch(response.data.result) {
                            case 'success':
                                self.updateArrUsersCredit(id, credit, 1);
                                Swal.fire(
                                    'Parabéns!',
                                    'O crédito foi alterado com sucesso!',
                                    'success'
                                )
                            break;
                            default:
                                Swal.fire(
                                    'Falha',
                                    response.data.message,
                                    'error'
                                )
                        }
                    }).catch((error) => {
                        try {
                            Swal.fire(
                                'Falha',
                                self.network_erros[error.status]['message'],
                                'error'
                            );
                        } catch(e) {
                            Swal.fire(
                                'Falha',
                                self.network_erros[408]['message'],
                                'error'
                            );
                        }
                    }).finally(() => {
                        self.bg_loading = false;
                    });
                }  
            }
        },
        async editUser(id) {

            const self = this;
            const index = this.users.findIndex((obj => obj.id == id));

            try {

                const user = this.users[index];

                const { value: formValues } = await Swal.fire({
                    title: 'Editar usuário',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    cancelButtonText: 'Cancelar',
                    confirmButtonText: 'Salvar',
                    html: `
                        <div class="swal-scroll-area">
                            <label class="d-block text-left mb-0">Nome</label>
                            <input id="swal-input1" class="swal2-input" value="${user.nome}" autocomplete="off" spellcheck="false">

                            <label class="d-block text-left mb-0">Endereço</label>
                            <input id="swal-input2" class="swal2-input" value="${user.endereco}" autocomplete="off" spellcheck="false">

                            <label class="d-block text-left mb-0">Telefone</label>
                            <input id="swal-input3" class="swal2-input" maxlength="14" value="${user.contato}" autocomplete="off" spellcheck="false">

                            <label class="d-block text-left mb-0">Pode cancelar apostas</label>
                            <select id="swal-input4" class="swal2-input w-100">
                                <option value="1" ${user.cancelar_aposta == 1 ? 'selected' : ''}>Sim</option>
                                <option value="2" ${user.cancelar_aposta == 2 ? 'selected' : ''}>Não</option>
                            </select>

                            <label class="d-block text-left mb-0" style="${user.tipo == 2 || user.tipo == 5 ? '' : 'display:none!important'}">Fazer lançamentos de caixa</label>
                            <select id="swal-input27" class="swal2-input w-100" style="${user.tipo == 2 || user.tipo == 5 ? '' : 'display:none!important'}">
                                <option value="sim" ${user.permissoes.fazer_lancamento == 'sim' ? 'selected' : ''}>Sim</option>
                                <option value="nao" ${user.permissoes.fazer_lancamento == 'nao' ? 'selected' : ''}>Não</option>
                            </select>

                            <label class="d-block text-left mb-0" style="${user.tipo == 2 || user.tipo == 5 ? 'display:none!important' : ''}">Crédito limite</label>
                            <input id="swal-input18" class="swal2-input" style="${user.tipo == 2 || user.tipo == 5 ? 'display:none!important' : ''}" value="${user.credito_limite}">

                            <label class="d-block text-left mb-0" style="${user.tipo == 2 || user.tipo == 5 ? 'display:none!important' : ''}">Crédito simples limite</label>
                            <input id="swal-input19" class="swal2-input" style="${user.tipo == 2 || user.tipo == 5 ? 'display:none!important' : ''}" value="${user.credito_simples_limite}">

                            <label class="d-block text-left mb-0" style="${user.tipo == 2 || user.tipo == 5 ? 'display:none!important' : ''}">${user.tipo == 4 ? 'Comissão por indicação (Afiliados)' : 'Comissão das apostas premiadas'}</label>
                            <input id="swal-input5" class="swal2-input" style="${user.tipo == 2 || user.tipo == 5 ? 'display:none!important' : ''}" value="${user.comissao_aposta_premiada}">

                            <label class="d-block text-left mb-0" style="${user.tipo == 2 || user.tipo == 5 ? 'display:none!important' : ''}">Apostar em jogo ao vivo</label>
                            <select id="swal-input6" class="swal2-input w-100" style="${user.tipo == 2 || user.tipo == 5 ? 'display:none!important' : ''}">
                                <option value="1" ${user.aposta_ao_vivo == 1 ? 'selected' : ''}>Sim</option>
                                <option value="2" ${user.aposta_ao_vivo == 2 ? 'selected' : ''}>Não</option>
                            </select>

                            <label class="d-block text-left mb-0" style="${user.tipo == 2 || user.tipo == 5 ? 'display:none!important' : ''}">Valor mínimo da aposta</label>
                            <input id="swal-input7" class="swal2-input" value="${user.valor_minimo_aposta}" style="${user.tipo == 2 || user.tipo == 5 ? 'display:none!important' : ''}">

                            <label class="d-block text-left mb-0" style="${user.tipo == 2 || user.tipo == 5 ? 'display:none!important' : ''}">Valor máximo da aposta</label>
                            <input id="swal-input8" class="swal2-input" value="${user.valor_maximo_aposta}" style="${user.tipo == 2 || user.tipo == 5 ? 'display:none!important' : ''}">

                            <label class="d-block text-left mb-0" style="${user.tipo == 2 || user.tipo == 5 ? 'display:none!important' : ''}">Mínimo de jogos por aposta</label>
                            <input id="swal-input10" class="swal2-input" value="${user.minimo_de_jogos_por_aposta}" style="${user.tipo == 2 || user.tipo == 5 ? 'display:none!important' : ''}">

                            <label class="d-block text-left mb-0" style="${user.tipo == 2 || user.tipo == 4 || user.tipo == 5 ? 'display:none!important' : ''}">Ativar crédito para apostas simples</label>
                            <select id="swal-input11" class="swal2-input w-100" style="${user.tipo == 2 || user.tipo == 4 || user.tipo == 5 ? 'display:none!important' : ''}">
                                <option value="sim" ${user.permissoes.credito_simples == 'sim' ? 'selected' : ''}>Sim</option>
                                <option value="nao" ${user.permissoes.credito_simples == 'nao' ? 'selected' : ''}>Não</option>
                            </select>

                            <label class="d-block text-left mb-0" style="${user.tipo == 2 || user.tipo == 3 || user.tipo == 5 ? 'display:none!important' : ''}">Permitir o encerramento de apostas</label>
                            <select id="swal-input20" class="swal2-input w-100" style="${user.tipo == 2 || user.tipo == 3 || user.tipo == 5 ? 'display:none!important' : ''}">
                                <option value="sim" ${user.permissoes.encerrar_aposta == 'sim' ? 'selected' : ''}>Sim</option>
                                <option value="nao" ${user.permissoes.encerrar_aposta == 'nao' ? 'selected' : ''}>Não</option>
                            </select>

                            <div style="${user.tipo == 1 || user.tipo == 4 ? 'display:none!important' : ''}">

                                <label class="d-block text-left mb-0" style="${user.tipo == 5 ? 'display:none!important' : ''}">Editar vendedor</label>
                                <select id="swal-input12" class="swal2-input w-100" style="${user.tipo == 5 ? 'display:none!important' : ''}">
                                    <option value="sim" ${user.permissoes.editar_vendedor == 'sim' ? 'selected' : ''}>Sim</option>
                                    <option value="nao" ${user.permissoes.editar_vendedor == 'nao' ? 'selected' : ''}>Não</option>
                                </select>

                                <label class="d-block text-left mb-0" style="${user.tipo == 2 ? 'display:none!important' : ''}">Editar gerente e vendedor</label>
                                <select id="swal-input22" class="swal2-input w-100" style="${user.tipo == 2 ? 'display:none!important' : ''}">
                                    <option value="sim" ${user.permissoes.editar_usuarios == 'sim' ? 'selected' : ''}>Sim</option>
                                    <option value="nao" ${user.permissoes.editar_usuarios == 'nao' ? 'selected' : ''}>Não</option>
                                </select>

                                <label class="d-block text-left mb-0" style="${user.tipo == 5 ? 'display:none!important' : ''}">Cadastrar vendedor</label>
                                <select id="swal-input13" class="swal2-input w-100" style="${user.tipo == 5 ? 'display:none!important' : ''}">
                                    <option value="sim" ${user.permissoes.cadastrar_vendedor == 'sim' ? 'selected' : ''}>Sim</option>
                                    <option value="nao" ${user.permissoes.cadastrar_vendedor == 'nao' ? 'selected' : ''}>Não</option>
                                </select>

                                <label class="d-block text-left mb-0" style="${user.tipo == 2 ? 'display:none!important' : ''}">Cadastrar gerente e vendedor</label>
                                <select id="swal-input9" class="swal2-input w-100" style="${user.tipo == 2 ? 'display:none!important' : ''}">
                                    <option value="sim" ${user.permissoes.cadastrar_usuarios == 'sim' ? 'selected' : ''}>Sim</option>
                                    <option value="nao" ${user.permissoes.cadastrar_usuarios == 'nao' ? 'selected' : ''}>Não</option>
                                </select>

                                <label class="d-block text-left mb-0" style="${user.tipo == 5 ? 'display:none!important' : ''}">Comissão do apurado</label>
                                <select id="swal-input14" class="swal2-input w-100" style="${user.tipo == 5 ? 'display:none!important' : ''}">
                                    <option value="sim" ${user.permissoes.comissao_do_apurado == 'sim' ? 'selected' : ''}>Sim</option>
                                    <option value="nao" ${user.permissoes.comissao_do_apurado == 'nao' ? 'selected' : ''}>Não</option>
                                </select>

                                <label class="d-block text-left mb-0">${user.tipo == 2 ? 'Liberar crédito para vendedor' : 'Liberar crédito para gerente e vendedor'}</label>
                                <select id="swal-input15" class="swal2-input w-100">
                                    <option value="sim" ${user.permissoes.liberar_credito == 'sim' ? 'selected' : ''}>Sim</option>
                                    <option value="nao" ${user.permissoes.liberar_credito == 'nao' ? 'selected' : ''}>Não</option>
                                </select>

                                <label class="d-block text-left mb-0" style="${user.tipo == 5 && self.hostname ? '' : 'display:none!important'}">Incluir comissões do gerente e vendedor</label>
                                <select id="swal-input28" class="swal2-input w-100" style="${user.tipo == 5 && self.hostname ? '' : 'display:none!important'}">
                                    <option value="sim" ${user.permissoes.incluir_comissoes == 'sim' ? 'selected' : ''}>Sim</option>
                                    <option value="nao" ${user.permissoes.incluir_comissoes == 'nao' ? 'selected' : ''}>Não</option>
                                </select>

                                <label class="d-block text-left mb-0">${user.tipo == 2 ? 'Alterar comissões do vendedor' : 'Alterar comissões do gerente e vendedor'}</label>
                                <select id="swal-input16" class="swal2-input w-100">
                                    <option value="sim" ${user.permissoes.alterar_comissoes == 'sim' ? 'selected' : ''}>Sim</option>
                                    <option value="nao" ${user.permissoes.alterar_comissoes == 'nao' ? 'selected' : ''}>Não</option>
                                </select>

                                <label class="d-block text-left mb-0" style="${user.tipo == 5 ? 'display:none!important' : ''}">Prestar contas com o vendedor</label>
                                <select id="swal-input17" class="swal2-input w-100" style="${user.tipo == 5 ? 'display:none!important' : ''}">
                                    <option value="sim" ${user.permissoes.prestar_contas == 'sim' ? 'selected' : ''}>Sim</option>
                                    <option value="nao" ${user.permissoes.prestar_contas == 'nao' ? 'selected' : ''}>Não</option>
                                </select>

                                <label class="d-block text-left mb-0" style="${user.tipo == 2 ? 'display:none!important' : ''}">Bloquear/Desbloquear gerente e vendedor</label>
                                <select id="swal-input21" class="swal2-input w-100" style="${user.tipo == 2 ? 'display:none!important' : ''}">
                                    <option value="sim" ${user.permissoes.bloquear_desbloquer_usuarios == 'sim' ? 'selected' : ''}>Sim</option>
                                    <option value="nao" ${user.permissoes.bloquear_desbloquer_usuarios == 'nao' ? 'selected' : ''}>Não</option>
                                </select>
                            </div> 
                            
                            <div style="${user.tipo == 1 || user.tipo == 4 ? 'display:block!important' : 'display:none!important'}">
                                <ul class="nav nav-tabs" id="myTab" role="tablist">
                                    <li class="nav-item">
                                        <a class="nav-link active" id="pregame-tab" data-toggle="tab" href="#pregame" role="tab" aria-controls="pregame" aria-selected="true">Pré-jogo</a>
                                    </li>
                                    <li class="nav-item">
                                        <a class="nav-link" id="live-tab" data-toggle="tab" href="#live" role="tab" aria-controls="live" aria-selected="false">Ao vivo</a>
                                    </li>
                                </ul>
                                <div class="tab-content mt-3" id="myTabContent">
                                    <div class="tab-pane fade show active" id="pregame" role="tabpanel" aria-labelledby="pregame-tab">
                                        <label class="d-block text-left mb-0">Cotação mínima para realizar aposta com um palpite</label>
                                        <input id="swal-input23" class="swal2-input" value="${user.min_valor_odd_um_palpite}">

                                        <label class="d-block text-left mb-0">Cotação mínima para realizar aposta com dois ou mais palpites</label>
                                        <input id="swal-input24" class="swal2-input" value="${user.min_valor_odd_mais_palpites}">    
                                    </div>
                                    <div class="tab-pane fade" id="live" role="tabpanel" aria-labelledby="live-tab">
                                        <label class="d-block text-left mb-0">Cotação mínima para realizar aposta com um palpite</label>
                                        <input id="swal-input25" class="swal2-input" value="${user.min_valor_odd_um_palpite_ao_vivo}">

                                        <label class="d-block text-left mb-0">Cotação mínima para realizar aposta com dois ou mais palpites</label>
                                        <input id="swal-input26" class="swal2-input" value="${user.min_valor_odd_mais_palpites_ao_vivo}">    
                                    </div>
                                </div>
                            </div>
                        </div>

                        ${user.tipo == 4 ? `
                            <button id="change-bank-details" class="btn btn-lg btn-secondary btn-block">
                                <i class="fas fa-university mr-2"></i>Alterar dados bancários
                            </button>
                        ` : `
                            <button id="change-commissions" class="btn btn-lg btn-secondary btn-block">
                                <i class="fas fa-percent mr-2"></i>${user.tipo == 2 || user.tipo == 5 ? 'Alterar comissão' : 'Alterar comissões'}
                            </button>
                        `}
                        
                        <button id="change-password" class="btn btn-lg btn-success btn-block">
                            <i class="fas fa-key mr-2"></i>Alterar senha
                        </button>
                    `,
                    focusConfirm: false,
                    preConfirm: () => {
                        return {
                            nome: document.getElementById('swal-input1').value,
                            endereco: document.getElementById('swal-input2').value,
                            contato: document.getElementById('swal-input3').value,
                            fazer_lancamento: document.getElementById('swal-input27').value,
                            cancelar_aposta: document.getElementById('swal-input4').value,
                            comissao_aposta_premiada: document.getElementById('swal-input5').value,
                            aposta_ao_vivo: document.getElementById('swal-input6').value,
                            valor_minimo_aposta: document.getElementById('swal-input7').value,
                            valor_maximo_aposta: document.getElementById('swal-input8').value,
                            minimo_de_jogos_por_aposta: document.getElementById('swal-input10').value,
                            credito_simples: document.getElementById('swal-input11').value,
                            editar_vendedor: document.getElementById('swal-input12').value,
                            editar_usuarios: document.getElementById('swal-input22').value,
                            cadastrar_vendedor: document.getElementById('swal-input13').value,
                            cadastrar_usuarios: document.getElementById('swal-input9').value,
                            comissao_do_apurado: document.getElementById('swal-input14').value,
                            liberar_credito: document.getElementById('swal-input15').value,
                            alterar_comissoes: document.getElementById('swal-input16').value,
                            prestar_contas: document.getElementById('swal-input17').value,
                            credito_limite: document.getElementById('swal-input18').value,
                            credito_simples_limite: document.getElementById('swal-input19').value,
                            encerrar_aposta: document.getElementById('swal-input20').value,
                            incluir_comissoes: document.getElementById('swal-input28').value,
                            bloquear_desbloquer_usuarios: document.getElementById('swal-input21').value,
                            min_valor_odd_um_palpite: document.getElementById('swal-input23').value,
                            min_valor_odd_mais_palpites: document.getElementById('swal-input24').value,
                            min_valor_odd_um_palpite_ao_vivo: document.getElementById('swal-input25').value,
                            min_valor_odd_mais_palpites_ao_vivo: document.getElementById('swal-input26').value
                        }
                    },
                    onOpen: () => {
                        document.getElementById("swal-input1").addEventListener("input", (e) => {
                           return e.target.value = e.target.value.replace(/[^a-zà-ú\s]+/gi, '');
                        });

                        document.getElementById("swal-input3").addEventListener("input", (e) => {
                           return e.target.value = e.target.value.replace(/[^0-9]/g, '').replace(/(\d{2})(\d{4})(\d{4})/, '($1) $2-$3');
                        });

                        document.getElementById("swal-input5").addEventListener("input", (e) => {
                           return e.target.value = e.target.value.replace(/[^0-9]/g, '');
                        });

                        document.getElementById("swal-input7").addEventListener("input", (e) => {
                           return e.target.value = e.target.value.replace(/[^0-9]/g, '');
                        });

                        document.getElementById("swal-input8").addEventListener("input", (e) => {
                           return e.target.value = e.target.value.replace(/[^0-9]/g, '');
                        });

                        document.getElementById("swal-input10").addEventListener("input", (e) => {
                           return e.target.value = e.target.value.replace(/[^0-9]/g, '');
                        });

                        document.getElementById("swal-input18").addEventListener("input", (e) => {
                           return e.target.value = e.target.value.replace(/[^0-9]/g, '');
                        });

                        document.getElementById("swal-input19").addEventListener("input", (e) => {
                           return e.target.value = e.target.value.replace(/[^0-9]/g, '');
                        });

                        document.getElementById("swal-input23").addEventListener("input", (e) => {
                            let value = e.target.value;
                            value = value.replace(/\D/g,"");  
                            value = value.replace(/[0-9]{12}/," ");  
                            value = value.replace(/(\d{1})(\d{8})$/,"$1.$2");
                            value = value.replace(/(\d{1})(\d{5})$/,"$1.$2");  
                            value = value.replace(/(\d{1})(\d{1,2})$/,"$1.$2");
                            return e.target.value = value;
                        });

                        document.getElementById("swal-input24").addEventListener("input", (e) => {
                            let value = e.target.value;
                            value = value.replace(/\D/g,"");  
                            value = value.replace(/[0-9]{12}/," ");  
                            value = value.replace(/(\d{1})(\d{8})$/,"$1.$2");
                            value = value.replace(/(\d{1})(\d{5})$/,"$1.$2");  
                            value = value.replace(/(\d{1})(\d{1,2})$/,"$1.$2");
                            return e.target.value = value;
                        });

                        document.getElementById("swal-input25").addEventListener("input", (e) => {
                            let value = e.target.value;
                            value = value.replace(/\D/g,"");  
                            value = value.replace(/[0-9]{12}/," ");  
                            value = value.replace(/(\d{1})(\d{8})$/,"$1.$2");
                            value = value.replace(/(\d{1})(\d{5})$/,"$1.$2");  
                            value = value.replace(/(\d{1})(\d{1,2})$/,"$1.$2");
                            return e.target.value = value;
                        });

                        document.getElementById("swal-input26").addEventListener("input", (e) => {
                            let value = e.target.value;
                            value = value.replace(/\D/g,"");  
                            value = value.replace(/[0-9]{12}/," ");  
                            value = value.replace(/(\d{1})(\d{8})$/,"$1.$2");
                            value = value.replace(/(\d{1})(\d{5})$/,"$1.$2");  
                            value = value.replace(/(\d{1})(\d{1,2})$/,"$1.$2");
                            return e.target.value = value;
                        });

                        document.getElementById("change-password").addEventListener("click", () => {
                            return self.changePassword(id, user.usuario);
                        });

                        document.getElementById(user.tipo == 4 ? "change-bank-details" : "change-commissions").addEventListener("click", () => {
                            return user.tipo == 4 ? self.changeBankDetails(id, user) : self.changeCommissions(id, user.usuario);
                        });
                    }
                });

                if (formValues.nome.toString().trim() == '') {
                    Swal.fire(
                        'Atenção',
                        'Informe o nome!',
                        'error'
                    );
                } else if (formValues.endereco.toString().trim() == '') {
                    Swal.fire(
                        'Atenção',
                        'Informe o endereço!',
                        'error'
                    );
                } else if (formValues.contato.toString().trim() == '') {
                    Swal.fire(
                        'Atenção',
                        'Informe o telefone!',
                        'error'
                    );
                } else if (formValues.contato.length < 14) {
                    Swal.fire(
                        'Atenção',
                        'Número de telefone inválido!',
                        'error'
                    );
                } else if (formValues.credito_limite.toString().trim() == '') {
                    Swal.fire(
                        'Atenção',
                        'Informe informe o crédito limite!',
                        'error'
                    );
                } else if (formValues.credito_simples_limite.toString().trim() == '') {
                    Swal.fire(
                        'Atenção',
                        'Informe informe o crédito simples limite!',
                        'error'
                    );
                } else if (formValues.comissao_aposta_premiada.toString().trim() == '') {
                    Swal.fire(
                        'Atenção',
                        'Informe a comissão de aposta premiada!',
                        'error'
                    );
                } else if (formValues.valor_minimo_aposta.toString().trim() == '') {
                    Swal.fire(
                        'Atenção',
                        'Informe o valor mínimo da aposta!',
                        'error'
                    );
                } else if (formValues.valor_minimo_aposta < 1) {
                    Swal.fire(
                        'Atenção',
                        'O valor mínimo da aposta não pode ser menor que um!',
                        'error'
                    );
                } else if (formValues.valor_maximo_aposta.toString().trim() == '') {
                    Swal.fire(
                        'Atenção',
                        'Informe o valor máximo da aposta!',
                        'error'
                    );
                } else if (formValues.valor_maximo_aposta < 1) {
                    Swal.fire(
                        'Atenção',
                        'O valor máximo da aposta não pode ser menor que um!',
                        'error'
                    );
                } else if (formValues.minimo_de_jogos_por_aposta.toString().trim() == '') {
                    Swal.fire(
                        'Atenção',
                        'Informe o mínimo de jogos por aposta!',
                        'error'
                    );
                } else if (formValues.minimo_de_jogos_por_aposta < 1) {
                    Swal.fire(
                        'Atenção',
                        'O mínimo de jogos por aposta não pode ser menor que um!',
                        'error'
                    );
                } else {

                    self.bg_loading = true;

                    api.post('dashboard/edit-user', {
                        id: id,
                        form: formValues
                    }).then((response) => {
                        switch(response.data.result) {
                            case 'success':
                                self.updateArrUsersValues(id, formValues);
                                Swal.fire(
                                    'Parabéns!',
                                    'Alterações salvas com sucesso!',
                                    'success'
                                );
                            break;
                            default:
                                Swal.fire(
                                    'Atenção',
                                    response.data.message,
                                    'error'
                                );
                        }
                    }).catch((error) => {
                        try {
                            Swal.fire(
                                'Falha',
                                self.network_erros[error.status]['message'],
                                'error'
                            );
                        } catch(e) {
                            Swal.fire(
                                'Falha',
                                self.network_erros[408]['message'],
                                'error'
                            );
                        }                        
                    }).finally(() => {
                        self.bg_loading = false;
                    });
                }
            } 
            catch(e) 
            {
                // window.console.warn(e.message);
            }
        },
        async assembleHTML(id, username) {
            
            let i = 1;
            let j = 1;
            let str1 = '';
            let str2 = '';
            let index = this.users.findIndex((obj => obj.id == id));
            
            try {

                const user = this.users[index];

                for (let key in user.comissoes) {
                     let commission = user.comissoes[key];
                     let label = i == 1 ? 'Comissão 1 jogo' : i == 20 ? 'Comissão 20 ou mais...' : `Comissão ${i} jogos`;
                     
                     if (user.tipo == 2 || user.tipo == 5) {

                        str1 += `<label class="d-block text-left mb-0" style="${i > 1 ? 'display:none!important': ''}">Comissão</label>`;
                        str1 += `<input id="swal-input-pregame${i}" style="${i > 1 ? 'display:none!important': ''}" class="swal2-input" value="${commission}" autocomplete="off" spellcheck="false">`;
                        str1 += `<input id="swal-input-inplay${i}" class="swal2-input d-none" value="${commission}" autocomplete="off" spellcheck="false">`;

                        i++;
                     }
                     else 
                     {
                        str1 += `<label class="d-block text-left mb-0">${label}</label>`;
                        str1 += `<input id="swal-input-pregame${i}" class="swal2-input" value="${commission}" autocomplete="off" spellcheck="false">`;
                         
                        i++;
                     }
                }

                for (let key in user.comissoes_ao_vivo) {
                     let commission = user.comissoes_ao_vivo[key];
                     let label = j == 1 ? 'Comissão 1 jogo' : j == 20 ? 'Comissão 20 ou mais...' : `Comissão ${j} jogos`;
                     
                     str2 += `<label class="d-block text-left mb-0">${label}</label>`;
                     str2 += `<input id="swal-input-inplay${j}" class="swal2-input" value="${commission}" autocomplete="off" spellcheck="false">`;
                     
                     j++;
                }
                
                return user.tipo == 1 ? `
                    <p class="mb-3">Você está alterando as comissões do <b>"${username}"</b>.</p>
                    <ul class="nav nav-tabs mb-3" id="myTab" role="tablist">
                        <li class="nav-item">
                            <a class="nav-link active" id="pregame-tab" data-toggle="tab" href="#pregame" role="tab" aria-controls="pregame" aria-selected="true">Pré-jogo</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" id="inplay-tab" data-toggle="tab" href="#inplay" role="tab" aria-controls="inplay" aria-selected="false">Ao vivo</a>
                        </li>
                    </ul>
                    <div class="tab-content" id="myTabContent">
                        <div class="tab-pane fade show active" id="pregame" role="tabpanel" aria-labelledby="pregame-tab">
                            ${str1}
                        </div>
                        <div class="tab-pane fade" id="inplay" role="tabpanel" aria-labelledby="inplay-tab">
                            ${str2}
                        </div>
                    </div>
                ` : `
                    <p class="mb-3">Você está alterando a comissão do <b>"${username}"</b>.</p>
                    ${str1}
                `;
            } 
            catch(e) 
            {
                // window.console.warn(e.message);
            }
        },
        async changeCommissions(id, username) {

            const self = this;
            const index = this.users.findIndex((obj => obj.id == id));

            try {

                const user = this.users[index];
                const html = await self.assembleHTML(id, username);

                const { value: formValues } = await Swal.fire({
                    title: 'Editar comissões',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    cancelButtonText: 'Cancelar',
                    confirmButtonText: 'Salvar',
                    html: `
                        <div class="swal-scroll-area">
                            ${html}
                        </div>
                    `,
                    focusConfirm: false,
                    preConfirm: () => {
                        return {
                            pregame: {
                                1: parseInt(document.getElementById('swal-input-pregame1').value),
                                2: parseInt(document.getElementById('swal-input-pregame2').value),
                                3: parseInt(document.getElementById('swal-input-pregame3').value),
                                4: parseInt(document.getElementById('swal-input-pregame4').value),
                                5: parseInt(document.getElementById('swal-input-pregame5').value),
                                6: parseInt(document.getElementById('swal-input-pregame6').value),
                                7: parseInt(document.getElementById('swal-input-pregame7').value),
                                8: parseInt(document.getElementById('swal-input-pregame8').value),
                                9: parseInt(document.getElementById('swal-input-pregame9').value),
                                10: parseInt(document.getElementById('swal-input-pregame10').value),
                                11: parseInt(document.getElementById('swal-input-pregame11').value),
                                12: parseInt(document.getElementById('swal-input-pregame12').value),
                                13: parseInt(document.getElementById('swal-input-pregame13').value),
                                14: parseInt(document.getElementById('swal-input-pregame14').value),
                                15: parseInt(document.getElementById('swal-input-pregame15').value),
                                16: parseInt(document.getElementById('swal-input-pregame16').value),
                                17: parseInt(document.getElementById('swal-input-pregame17').value),
                                18: parseInt(document.getElementById('swal-input-pregame18').value),
                                19: parseInt(document.getElementById('swal-input-pregame19').value),
                                20: parseInt(document.getElementById('swal-input-pregame20').value)
                            },
                            inplay: {
                                1: parseInt(document.getElementById('swal-input-inplay1').value),
                                2: parseInt(document.getElementById('swal-input-inplay2').value),
                                3: parseInt(document.getElementById('swal-input-inplay3').value),
                                4: parseInt(document.getElementById('swal-input-inplay4').value),
                                5: parseInt(document.getElementById('swal-input-inplay5').value),
                                6: parseInt(document.getElementById('swal-input-inplay6').value),
                                7: parseInt(document.getElementById('swal-input-inplay7').value),
                                8: parseInt(document.getElementById('swal-input-inplay8').value),
                                9: parseInt(document.getElementById('swal-input-inplay9').value),
                                10: parseInt(document.getElementById('swal-input-inplay10').value),
                                11: parseInt(document.getElementById('swal-input-inplay11').value),
                                12: parseInt(document.getElementById('swal-input-inplay12').value),
                                13: parseInt(document.getElementById('swal-input-inplay13').value),
                                14: parseInt(document.getElementById('swal-input-inplay14').value),
                                15: parseInt(document.getElementById('swal-input-inplay15').value),
                                16: parseInt(document.getElementById('swal-input-inplay16').value),
                                17: parseInt(document.getElementById('swal-input-inplay17').value),
                                18: parseInt(document.getElementById('swal-input-inplay18').value),
                                19: parseInt(document.getElementById('swal-input-inplay19').value),
                                20: parseInt(document.getElementById('swal-input-inplay20').value)
                            }
                        }
                    },
                    onOpen: () => {
                        document.getElementById('swal-input-pregame1').addEventListener("input", (e) => {
                           return e.target.value = e.target.value.replace(/[^0-9]/g, '');
                        });

                        document.getElementById('swal-input-pregame2').addEventListener("input", (e) => {
                           return e.target.value = e.target.value.replace(/[^0-9]/g, '');
                        });

                        document.getElementById('swal-input-pregame3').addEventListener("input", (e) => {
                           return e.target.value = e.target.value.replace(/[^0-9]/g, '');
                        });

                        document.getElementById('swal-input-pregame4').addEventListener("input", (e) => {
                           return e.target.value = e.target.value.replace(/[^0-9]/g, '');
                        });

                        document.getElementById('swal-input-pregame5').addEventListener("input", (e) => {
                           return e.target.value = e.target.value.replace(/[^0-9]/g, '');
                        });

                        document.getElementById('swal-input-pregame6').addEventListener("input", (e) => {
                           return e.target.value = e.target.value.replace(/[^0-9]/g, '');
                        });

                        document.getElementById('swal-input-pregame7').addEventListener("input", (e) => {
                           return e.target.value = e.target.value.replace(/[^0-9]/g, '');
                        });

                        document.getElementById('swal-input-pregame8').addEventListener("input", (e) => {
                           return e.target.value = e.target.value.replace(/[^0-9]/g, '');
                        });

                        document.getElementById('swal-input-pregame9').addEventListener("input", (e) => {
                           return e.target.value = e.target.value.replace(/[^0-9]/g, '');
                        });

                        document.getElementById('swal-input-pregame10').addEventListener("input", (e) => {
                           return e.target.value = e.target.value.replace(/[^0-9]/g, '');
                        });
                        
                        document.getElementById('swal-input-pregame11').addEventListener("input", (e) => {
                           return e.target.value = e.target.value.replace(/[^0-9]/g, '');
                        });

                        document.getElementById('swal-input-pregame12').addEventListener("input", (e) => {
                           return e.target.value = e.target.value.replace(/[^0-9]/g, '');
                        });

                        document.getElementById('swal-input-pregame13').addEventListener("input", (e) => {
                           return e.target.value = e.target.value.replace(/[^0-9]/g, '');
                        });

                        document.getElementById('swal-input-pregame14').addEventListener("input", (e) => {
                           return e.target.value = e.target.value.replace(/[^0-9]/g, '');
                        });

                        document.getElementById('swal-input-pregame15').addEventListener("input", (e) => {
                           return e.target.value = e.target.value.replace(/[^0-9]/g, '');
                        });

                        document.getElementById('swal-input-pregame16').addEventListener("input", (e) => {
                           return e.target.value = e.target.value.replace(/[^0-9]/g, '');
                        });

                        document.getElementById('swal-input-pregame17').addEventListener("input", (e) => {
                           return e.target.value = e.target.value.replace(/[^0-9]/g, '');
                        });

                        document.getElementById('swal-input-pregame19').addEventListener("input", (e) => {
                           return e.target.value = e.target.value.replace(/[^0-9]/g, '');
                        });

                        document.getElementById('swal-input-pregame20').addEventListener("input", (e) => {
                           return e.target.value = e.target.value.replace(/[^0-9]/g, '');
                        });

                        document.getElementById('swal-input-inplay1').addEventListener("input", (e) => {
                           return e.target.value = e.target.value.replace(/[^0-9]/g, '');
                        });

                        document.getElementById('swal-input-inplay2').addEventListener("input", (e) => {
                           return e.target.value = e.target.value.replace(/[^0-9]/g, '');
                        });

                        document.getElementById('swal-input-inplay3').addEventListener("input", (e) => {
                           return e.target.value = e.target.value.replace(/[^0-9]/g, '');
                        });

                        document.getElementById('swal-input-inplay4').addEventListener("input", (e) => {
                           return e.target.value = e.target.value.replace(/[^0-9]/g, '');
                        });

                        document.getElementById('swal-input-inplay5').addEventListener("input", (e) => {
                           return e.target.value = e.target.value.replace(/[^0-9]/g, '');
                        });

                        document.getElementById('swal-input-inplay6').addEventListener("input", (e) => {
                           return e.target.value = e.target.value.replace(/[^0-9]/g, '');
                        });

                        document.getElementById('swal-input-inplay7').addEventListener("input", (e) => {
                           return e.target.value = e.target.value.replace(/[^0-9]/g, '');
                        });

                        document.getElementById('swal-input-inplay8').addEventListener("input", (e) => {
                           return e.target.value = e.target.value.replace(/[^0-9]/g, '');
                        });

                        document.getElementById('swal-input-inplay9').addEventListener("input", (e) => {
                           return e.target.value = e.target.value.replace(/[^0-9]/g, '');
                        });

                        document.getElementById('swal-input-inplay10').addEventListener("input", (e) => {
                           return e.target.value = e.target.value.replace(/[^0-9]/g, '');
                        });
                        
                        document.getElementById('swal-input-inplay11').addEventListener("input", (e) => {
                           return e.target.value = e.target.value.replace(/[^0-9]/g, '');
                        });

                        document.getElementById('swal-input-inplay12').addEventListener("input", (e) => {
                           return e.target.value = e.target.value.replace(/[^0-9]/g, '');
                        });

                        document.getElementById('swal-input-inplay13').addEventListener("input", (e) => {
                           return e.target.value = e.target.value.replace(/[^0-9]/g, '');
                        });

                        document.getElementById('swal-input-inplay14').addEventListener("input", (e) => {
                           return e.target.value = e.target.value.replace(/[^0-9]/g, '');
                        });

                        document.getElementById('swal-input-inplay15').addEventListener("input", (e) => {
                           return e.target.value = e.target.value.replace(/[^0-9]/g, '');
                        });

                        document.getElementById('swal-input-inplay16').addEventListener("input", (e) => {
                           return e.target.value = e.target.value.replace(/[^0-9]/g, '');
                        });

                        document.getElementById('swal-input-inplay17').addEventListener("input", (e) => {
                           return e.target.value = e.target.value.replace(/[^0-9]/g, '');
                        });
                        document.getElementById('swal-input-inplay19').addEventListener("input", (e) => {
                           return e.target.value = e.target.value.replace(/[^0-9]/g, '');
                        });

                        document.getElementById('swal-input-inplay20').addEventListener("input", (e) => {
                           return e.target.value = e.target.value.replace(/[^0-9]/g, '');
                        });
                    }
                });

                if (formValues) {

                    const filter_pregame = Object.values(formValues.pregame).some((item) => item.toString() == '');
                    const filter_inplay = Object.values(formValues.inplay).some((item) => item.toString() == '');
                   
                    if (!filter_pregame && !filter_inplay) {

                        self.bg_loading = true;

                        user.comissoes = formValues.pregame;
                        user.comissoes_ao_vivo = formValues.inplay;

                        api.post('dashboard/edit-user/commissions', {
                            id: id,
                            inplay: formValues.inplay,
                            pregame: formValues.pregame
                        }).then((response) => {
                            switch(response.data.result) {
                                case 'success':
                                    Swal.fire(
                                        'Parabéns!',
                                        'As alterações foram salvas com sucesso!',
                                        'success'
                                    );
                                break;
                                default:
                                    Swal.fire(
                                        'Falha!',
                                        response.data.message,
                                        'error'
                                    )
                                break;
                            }
                        }).catch((error) => {
                            try {
                                Swal.fire(
                                    'Falha',
                                    self.network_erros[error.status]['message'],
                                    'error'
                                );
                            } catch(e) {
                                Swal.fire(
                                    'Falha',
                                    self.network_erros[408]['message'],
                                    'error'
                                );
                            }
                        }).finally(() => {
                            self.bg_loading = false;
                        });
                    } 
                    else 
                    {
                        Swal.fire(
                            'Atenção!',
                            'Preencha todos os campos!',
                            'warning'
                        );
                    }
                }
            }
            catch(e) 
            {
                // window.console.warn(e.message);
            }
        },
        async changeBankDetails(id, user) {

            const self = this;

            const { value: formValues } = await Swal.fire({
                title: 'Alterar dados bancários',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                cancelButtonText: 'Cancelar',
                confirmButtonText: 'Salvar',
                html: `
                    <label class="d-block text-left mb-0">Banco</label>
                    <input id="swal-input1" class="swal2-input" value="${user.dados_bancarios.banco}" autocomplete="off" spellcheck="false">

                    <label class="d-block text-left mb-0">Tipo de conta</label>
                    <select id="swal-input2" class="swal2-input w-100">
                        <option value="Corrente" ${user.dados_bancarios.tipo == 'Corrente' ? 'selected' : ''}>Corrente</option>
                        <option value="Poupança" ${user.dados_bancarios.tipo == 'Poupança' ? 'selected' : ''}>Poupança</option>
                    </select>

                    <label class="d-block text-left mb-0">Agência</label>
                    <input id="swal-input3" class="swal2-input" value="${user.dados_bancarios.agencia}" autocomplete="off" spellcheck="false">

                    <label class="d-block text-left mb-0">Conta</label>
                    <input id="swal-input4" class="swal2-input" value="${user.dados_bancarios.conta}" autocomplete="off" spellcheck="false">

                    <label class="d-block text-left mb-0">Dígito</label>
                    <input id="swal-input5" class="swal2-input" value="${user.dados_bancarios.digito}" autocomplete="off" spellcheck="false">

                    <div class="text-center pb-3">
                        <img src="/images/pix-bc-logo-4.png" width="200" border="0" alt="PIX">
                    </div>

                    <label class="d-block text-left mb-0">Tipo de chave pix</label>
                    <select id="swal-input6" class="swal2-input w-100">
                        <option value="cpf_cnpj" ${user.dados_bancarios.pix_tipo == 'cpf_cnpj' ? 'selected' : ''}>CPF/CNPJ</option>
                        <option value="email" ${user.dados_bancarios.pix_tipo == 'email' ? 'selected' : ''}>E-mail</option>
                        <option value="celular" ${user.dados_bancarios.pix_tipo == 'celular' ? 'selected' : ''}>Celular</option>
                        <option value="chave" ${user.dados_bancarios.pix_tipo == 'chave' ? 'selected' : ''}>Chave aleatória</option>
                    </select>

                    <label class="d-block text-left mb-0">Chave pix</label>
                    <input id="swal-input7" class="swal2-input" value="${user.dados_bancarios.pix}" autocomplete="off" spellcheck="false">
                `,
                focusConfirm: false,
                preConfirm: () => {
                    return {
                        banco: document.getElementById('swal-input1').value,
                        tipo: document.getElementById('swal-input2').value,
                        agencia: document.getElementById('swal-input3').value,
                        conta: document.getElementById('swal-input4').value,
                        digito: document.getElementById('swal-input5').value,
                        pix_tipo: document.getElementById('swal-input6').value,
                        pix: document.getElementById('swal-input7').value
                    }
                },
                onOpen: () => {
                    document.getElementById("swal-input1").addEventListener("input", (e) => {
                        return e.target.value = e.target.value.replace(/[^a-zà-ú-0-9.\s]+/gi, '');
                    });

                    document.getElementById("swal-input3").addEventListener("input", (e) => {
                        return e.target.value = e.target.value.replace(/[^0-9]/g, '');
                    });

                    document.getElementById("swal-input4").addEventListener("input", (e) => {
                        return e.target.value = e.target.value.replace(/[^0-9]/g, '');
                    });

                    document.getElementById("swal-input5").addEventListener("input", (e) => {
                        return e.target.value = e.target.value.replace(/[^0-9]/g, '');
                    });
                }
            });

            if (formValues instanceof Object) {
                
                if (formValues.banco.trim() == '') {
                    Swal.fire(
                        'Atenção!',
                        'Informe o nome do banco!',
                        'warning'
                    )
                } else if (formValues.agencia.trim() == '') {
                    Swal.fire(
                        'Atenção!',
                        'Informe a agência do banco!',
                        'warning'
                    )
                } else if (formValues.conta.trim() == '') {
                    Swal.fire(
                        'Atenção!',
                        'Informe a conta do banco!',
                        'warning'
                    )
                } else if (formValues.digito.trim() == '') {
                    Swal.fire(
                        'Atenção!',
                        'Informe o dígito da conta!',
                        'warning'
                    )
                } else {

                    self.bg_loading = true;

                    api.post('dashboard/edit-bank-details', {
                        id: user.id,
                        form: formValues
                    }).then((response) => {
                        switch(response.data.result) {
                            case 'success':
                                Swal.fire(
                                    'Parabéns!',
                                    'As alterações foram salvas com sucesso!',
                                    'success'
                                );
                            break;
                            default:
                                Swal.fire(
                                    'Falha!',
                                    response.data.message,
                                    'error'
                                );
                            break;
                        }
                    }).catch((error) => {
                        try {
                            Swal.fire(
                                'Falha',
                                self.network_erros[error.status]['message'],
                                'error'
                            );
                        } catch(e) {
                            Swal.fire(
                                'Falha',
                                self.network_erros[408]['message'],
                                'error'
                            );
                        }
                    }).finally(() => {
                        self.bg_loading = false;
                    });
                }
            }
        },
        async changePassword(id, username) {

            const self = this;
            const passFilter = /^[a-zA-Z0-9]*$/;

            const { value: password } = await Swal.fire({
                title: 'Alterar senha',
                html: `Você está alterando a senha do <b>"${username}"</b>.`,
                input: 'password',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                cancelButtonText: 'Cancelar',
                inputPlaceholder: 'Informe a nova senha!',
                confirmButtonText: 'Alterar',
                inputValidator: (value) => {

                    if (!value.trim()) {
                        return 'Informe a senha!'
                    } else if (value.trim().length < 4) {
                        return 'A senha tem que ter no mínimo 4 caracteres!'
                    } else if (!passFilter.test(value)) {
                        return 'Informe apenas letras e números!'
                    }
                },
                onOpen: () => {
                    document.getElementsByClassName('swal2-input')[0].addEventListener('input', (e) => {
                        return e.target.value = e.target.value.replace(/[^a-zA-Z0-9]/g, '');
                    });
                }
            });

            if (password) {
                
                self.bg_loading = true;

                api.post('dashboard/change-password', {
                    id: id,
                    password: password
                }).then((response) => {
                    switch(response.data.result) {
                        case 'success':
                            Swal.fire(
                                'Parabéns!',
                                'Senha alterada com sucesso!',
                                'success'
                            );
                        break;
                        default:
                            Swal.fire(
                                'Falha',
                                response.data.message,
                                'error'
                            );
                    }
                }).catch((error) => {
                    try {
                        Swal.fire(
                            'Falha',
                            self.network_erros[error.status]['message'],
                            'error'
                        );
                    } catch(e) {
                        Swal.fire(
                            'Falha',
                            self.network_erros[408]['message'],
                            'error'
                        );
                    }
                }).finally(() => {
                    self.bg_loading = false;
                });
            }
        },
        updateArrUsers(id, action) {

            const index = this.users.findIndex((obj => obj.id == id));

            try {

                const user = this.users[index];

                user.ativo = action;
                user.situacao = action == 1 ? 'Ativo' : 'Bloqueado';
            } catch(e) {
                // window.console.warn(e.message);
            }
        },
        updateArrUsersCredit(id, credit, credit_type) {

            const index = this.users.findIndex((obj => obj.id == id));

            try {

                const user = this.users[index];

                if (credit_type == 1) {
                    user.credito = credit;
                } else {
                    user.credito_simples = credit;
                }
            } catch(e) {
                // window.console.warn(e.message);
            }
        },
        updateArrUsersValues(id, formValues) {

            const index = this.users.findIndex((obj => obj.id == id));

            try {

                const user = this.users[index];

                user.nome = formValues.nome;
                user.contato = formValues.contato;
                user.endereco = formValues.endereco;
                user.aposta_ao_vivo = formValues.aposta_ao_vivo;
                user.cancelar_aposta = formValues.cancelar_aposta;
                user.valor_minimo_aposta = formValues.valor_minimo_aposta;
                user.valor_maximo_aposta = formValues.valor_maximo_aposta;
                user.comissao_aposta_premiada = formValues.comissao_aposta_premiada;
                user.minimo_de_jogos_por_aposta = formValues.minimo_de_jogos_por_aposta;
                user.permissoes.editar_vendedor = formValues.editar_vendedor;
                user.permissoes.editar_usuarios = formValues.editar_usuarios;
                user.permissoes.cadastrar_vendedor = formValues.cadastrar_vendedor;
                user.permissoes.cadastrar_usuarios = formValues.cadastrar_usuarios;
                user.permissoes.liberar_credito = formValues.liberar_credito;
                user.permissoes.alterar_comissoes = formValues.alterar_comissoes;
                user.permissoes.comissao_do_apurado = formValues.comissao_do_apurado;
                user.permissoes.bloquear_desbloquer_usuarios = formValues.bloquear_desbloquer_usuarios;
            } catch(e) {
                // window.console.warn(e.message);
            }
        },
        searchUser(event) {
            this.type = '';
            this.search = event.target.value;
        },
        removeAccents(str) {

            let string = str;
            let map = {
                a : /[\xE0-\xE6]/g,
                A : /[\xC0-\xC6]/g,
                e : /[\xE8-\xEB]/g,
                E : /[\xC8-\xCB]/g,
                i : /[\xEC-\xEF]/g,
                I : /[\xCC-\xCF]/g,
                o : /[\xF2-\xF6]/g,
                O : /[\xD2-\xD6]/g,
                u : /[\xF9-\xFC]/g,
                U : /[\xD9-\xDC]/g,
                c : /\xE7/g,
                C : /\xC7/g,
                n : /\xF1/g,
                N : /\xD1/g,
            };

            for (let key in map) {
                let rex = map[key];
                string = string.replace(rex, key);
            }

            return string;
        },
        addManager(id) {

            const self = this;

            self.bg_loading = true;

            api.get('dashboard/users/2').then((response) => {
                switch(response.data.result) {
                    case 'success':
                        self.dialogManager(id, response.data.users.sort((a, b) => {
                            return self.removeAccents(a.nome).localeCompare(self.removeAccents(b.nome), 'fr', {
                                ignorePunctuation: true
                            });
                        }));
                    break;
                    case 'nothing_found':
                        Swal.fire(
                            'Atenção!',
                            'Nenhum gerente encontrado!',
                            'warning'
                        );
                    break;
                    default:
                        Swal.fire(
                            'Atenção!',
                            'Ocorreu um erro, tente novamente!',
                            'warning'
                        );
                    break;
                }
            }).catch((error) => {
                try {
                    Swal.fire(
                        'Falha',
                        self.network_erros[error.status]['message'],
                        'error'
                    );
                } catch(e) {
                    Swal.fire(
                        'Falha',
                        self.network_erros[408]['message'],
                        'error'
                    );
                }
            }).finally(() => {
                self.bg_loading = false;
            });
        },
        addSupervisor(id) {

            const self = this;

            self.bg_loading = true;

            api.get('dashboard/users/5').then((response) => {
                switch(response.data.result) {
                    case 'success':
                        self.dialogSupervisor(id, response.data.users.sort((a, b) => {
                            return self.removeAccents(a.nome).localeCompare(self.removeAccents(b.nome), 'fr', {
                                ignorePunctuation: true
                            });
                        }));
                    break;
                    case 'nothing_found':
                        Swal.fire(
                            'Atenção!',
                            'Nenhum supervisor encontrado!',
                            'warning'
                        );
                    break;
                    default:
                        Swal.fire(
                            'Atenção!',
                            'Ocorreu um erro, tente novamente!',
                            'warning'
                        );
                    break;
                }
            }).catch((error) => {
                try {
                    Swal.fire(
                        'Falha',
                        self.network_erros[error.status]['message'],
                        'error'
                    );
                } catch(e) {
                    Swal.fire(
                        'Falha',
                        self.network_erros[408]['message'],
                        'error'
                    );
                }
            }).finally(() => {
                self.bg_loading = false;
            });
        },
        async dialogManager(id, items) {

            const self = this;
            const user = self.users.find((obj => obj.id == id));

            const { value: formValues } = await Swal.fire({
                title: 'Alterar gerente',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                cancelButtonText: 'Cancelar',
                confirmButtonText: 'Alterar',
                html: `
                    <label class="d-block text-left mb-0">Escolha o gerente para o vendedor: <b>${user.nome}</b></label>
                    <input type="hidden" id="swal-input1" />
                    <div id="swal-virtual-select" data-style="swall"></div>
                    <div id="swal-append"></div>
                `,
                customClass: {
                    actions: 'swal2-actions swal-virtual-select'
                },
                focusConfirm: false,
                preConfirm: () => {
                    return {
                        gerente_id: document.getElementById('swal-input1').value,
                    }
                },
                onOpen: () => {

                    const options = [];
                    const users = items.sort((a, b) => {
                        return a.nome.localeCompare(b.nome);
                    });

                    let selectedValue = '';
                    const total_users = users.length;

                    if (total_users) {
                        for (const userItem of users) {

                            if (user.gerente_id == userItem.id) {
                                selectedValue = userItem.id;
                            }

                            options.push({
                                value: userItem.id,
                                label: userItem.nome,
                                description: userItem.endereco
                            });
                        }
                    }

                    window.VirtualSelect.init({
                        ele: '#swal-virtual-select',
                        search: true,
                        placeholder: 'Selecione um gerente',
                        noOptionsText: 'Nenhuma opção disponível',
                        noSearchResultsText: 'Nenhum resultado encontrado',
                        searchPlaceholderText: `Buscar gerente (${total_users})...`,
                        hasOptionDescription: true,
                        selectedValue: selectedValue,
                        options: options
                    });

                    if (document.querySelector('#swal-virtual-select')) {
                        document.querySelector('#swal-virtual-select').addEventListener('change', function() {
                            document.getElementById('swal-input1').value = this.value;
                        });
                    }
                }
            });

            if (formValues instanceof Object) {

                if (formValues.gerente_id == '') return;

                Swal.fire({
                    title: 'Confirmação',
                    html: `Você deseja mesmo alterar o gerente do vendedor: <b>${user.nome}</b></p>`,
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    cancelButtonText: 'Cancelar',
                    confirmButtonText: 'Sim'
                }).then((result) => {

                    if (result.value) {

                        self.bg_loading = true;

                        api.put(`dashboard/change-manager/${id}/${formValues.gerente_id}`).then((response) => {
                            switch(response.data.result) {
                                case 'success':
                                    Swal.fire(
                                        'Parabéns!',
                                        'As alterações foram salvas com sucesso!',
                                        'success'
                                    );
                                break;
                                default:
                                    Swal.fire(
                                        'Atenção!',
                                        response.data.message,
                                        'warning'
                                    );
                            }
                        }).catch((error) => {
                            try {
                                Swal.fire(
                                    'Falha',
                                    self.network_erros[error.status]['message'],
                                    'error'
                                );
                            } catch(e) {
                                Swal.fire(
                                    'Falha',
                                    self.network_erros[408]['message'],
                                    'error'
                                );
                            }
                        }).finally(() => {
                            self.bg_loading = false;
                        });                        
                    }
                });
            }
        },
        async dialogSupervisor(id, items) {

            const self = this;
            const user = self.users.find((obj => obj.id == id));

            const { value: formValues } = await Swal.fire({
                title: 'Alterar supervisor',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                cancelButtonText: 'Cancelar',
                confirmButtonText: 'Alterar',
                html: `
                    <label class="d-block text-left mb-0">Escolha o supervisor para o gerente: <b>${user.nome}</b></label>
                    <input type="hidden" id="swal-input1" />
                    <div id="swal-virtual-select" data-style="swall"></div>
                    <div id="swal-append"></div>
                `,
                customClass: {
                    actions: 'swal2-actions swal-virtual-select'
                },
                focusConfirm: false,
                preConfirm: () => {
                    return {
                        supervisor_id: document.getElementById('swal-input1').value,
                    }
                },
                onOpen: () => {

                    const options = [];
                    const users = items.sort((a, b) => {
                        return a.nome.localeCompare(b.nome);
                    });

                    let selectedValue = '';
                    const total_users = users.length;

                    if (total_users) {
                        for (const userItem of users) {

                            if (user.supervisor_id == userItem.id) {
                                selectedValue = userItem.id
                            }

                            options.push({
                                value: userItem.id,
                                label: userItem.nome,
                                description: userItem.endereco
                            });
                        }
                    }

                    window.VirtualSelect.init({
                        ele: '#swal-virtual-select',
                        search: true,
                        placeholder: 'Selecione um supervisor',
                        noOptionsText: 'Nenhuma opção disponível',
                        noSearchResultsText: 'Nenhum resultado encontrado',
                        searchPlaceholderText: `Buscar supervisor (${total_users})...`,
                        hasOptionDescription: true,
                        selectedValue: selectedValue,
                        options: options
                    });

                    if (document.querySelector('#swal-virtual-select')) {
                        document.querySelector('#swal-virtual-select').addEventListener('change', function() {
                            document.getElementById('swal-input1').value = this.value;
                        });
                    }
                }
            });

            if (formValues instanceof Object) {

                if (formValues.supervisor_id == '') return;

                Swal.fire({
                    title: 'Confirmação',
                    html: `Você deseja mesmo alterar o supervisor do gerente: <b>${user.nome}</b></p>`,
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    cancelButtonText: 'Cancelar',
                    confirmButtonText: 'Sim'
                }).then((result) => {

                    if (result.value) {

                        self.bg_loading = true;

                        api.put(`dashboard/change-supervisor/${id}/${formValues.supervisor_id}`).then((response) => {
                            switch(response.data.result) {
                                case 'success':
                                    Swal.fire(
                                        'Parabéns!',
                                        'As alterações foram salvas com sucesso!',
                                        'success'
                                    );
                                break;
                                default:
                                    Swal.fire(
                                        'Atenção!',
                                        response.data.message,
                                        'warning'
                                    );
                            }
                        }).catch((error) => {
                            try {
                                Swal.fire(
                                    'Falha',
                                    self.network_erros[error.status]['message'],
                                    'error'
                                );
                            } catch(e) {
                                Swal.fire(
                                    'Falha',
                                    self.network_erros[408]['message'],
                                    'error'
                                );
                            }
                        }).finally(() => {
                            self.bg_loading = false;
                        });                        
                    }
                });
            }
        },
        viewInfo(data) {

            try {

                if (Object.values(data).length == 0) {
                    Swal.fire(
                        'Atenção!',
                        'Nenhuma informação disponível!',
                        'warning'
                    );
                }
                else
                {
                    Swal.fire(
                        'Informações',
                        `<div class="row bg-ddd rounded-top">
                            <div class="col text-truncate pt-2 pb-2">Pais</div>
                            <div class="col text-truncate pt-2 pb-2">${data.pais}</div>
                        </div>
                        <div class="row bg-f1f1f1">
                            <div class="col text-truncate pt-2 pb-2">Estado</div>
                            <div class="col text-truncate pt-2 pb-2">${data.estado}</div>
                        </div>
                        <div class="row bg-ddd">
                            <div class="col text-truncate pt-2 pb-2">Cidade</div>
                            <div class="col text-truncate pt-2 pb-2">${data.cidade}</div>
                        </div>
                        <div class="row bg-f1f1f1">
                            <div class="col text-truncate pt-2 pb-2">Latitude</div>
                            <div class="col text-truncate pt-2 pb-2">${data.latitude}</div>
                        </div>
                        <div class="row bg-ddd">
                            <div class="col text-truncate pt-2 pb-2">Longitude</div>
                            <div class="col text-truncate pt-2 pb-2">${data.longitude}</div>
                        </div>
                        <div class="row bg-f1f1f1 rounded-bottom">
                            <div class="col text-truncate pt-2 pb-2">Provedor (NET)</div>
                            <div class="col text-truncate pt-2 pb-2">${data.provedor}</div>
                        </div>
                        <div class="row mt-3">
                            <div class="col-12 text-center text-truncate">
                                <span class="font-weight-bold">Localização do vendedor</span>
                            </div>
                            <div class="w-100 mt-3 iframe-map rounded">
                                ${this.googleMap(data.latitude, data.longitude)}
                            </div>
                        </div>
                        `
                    )
                }
            } 
            catch(e) 
            {
                Swal.fire(
                    'Atenção!',
                    'Nenhuma informação disponível!',
                    'warning'
                );
            }
        },
        filterAbsent() {
            
            const users = [];
            const now = moment();

            this.users_absent = [];
            this.filter_absent = true;
            
            this.users.forEach((item) => {
                const d = new Date(item.horario_da_ultima_aposta);
                if (d instanceof Date && !isNaN(d)) {
                    const diff = now.diff(moment(d), 'days');
                    if (diff >= 30) {
                        users.push(item);
                        this.users_absent.push({
                            id: item.id,
                            nome: item.nome,
                            endereco: item.endereco,
                            atividade: moment(item.horario_da_ultima_aposta).locale('pt-br').fromNow()
                        });
                    }
                }
            });

            if (users.length == 0) {
                Swal.fire(
                    'Atenção!',
                    'Nenhum vendedor ausente por mais de 30 dias!',
                    'warning'
                );
            } else {
                this.users = users;
            }
        },
        deleteUsersAbsent() {

            let timerInterval;

            const self = this;
            const date = moment().format('DD/MM/YYYY');
            const users_total = self.users_absent.length;
            
            if (users_total == 0) {
                Swal.fire(
                    'Atenção!',
                    'Nenhum vendedor ausente por mais de 30 dias!',
                    'warning'
                );
            } else {
                Swal.fire({
                    title: 'Exclusão em massa',
                    html: `
                        <div class="border-bottom pb-3">
                            <span>Antes de continuar verifique se a data está correta (<strong>${date}</strong>). <br /> Caso não esteja, cancele essa operação e corrija.</span>
                            <hr />
                            <span>Você está excluindo (<strong>${users_total})</strong> vendedores que estão ausentes por mais de 30 dias. <br /> Lembrando que caso confirme, essa exclusão poderá causar problemas no financeiro, já que não será mais possível fazer o cruzamento de dados com os vendedores excluídos do sistema. <hr /> <strong class="text-danger">Essa operação é de total responsabilidade sua.</strong></span>
                        </div>
                        <div class="swal-scroll-area">
                            <ul class="list-group list-group-flush">
                                ${self.users_absent.map((item) => {
                                    return `<li class="list-group-item">
                                        <strong>${item.nome}</strong>
                                        <p class="m-0">
                                            <small>${item.endereco}</small>
                                            <p class="m-0">
                                                <small>Ativo: <span class="text-info">${item.atividade}</span></small>
                                            </p
                                        </p>
                                    </li>`
                                }).join('')}
                            </ul>
                        </div>
                    `,
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    cancelButtonText: 'Cancelar',
                    confirmButtonText: `Excluir (<strong>${users_total})</strong>`
                }).then((result) => {

                    if (result.value) {

                        self.bg_loading = true;

                        api.post('dashboard/delete-users-absent', {
                            users: self.users_absent.map((item) => item.id)
                        }).then((response) => {
                            switch(response.data.result) {
                                case 'success':
                                    Swal.fire({
                                        title: `Parabéns!`,
                                        html: `
                                            <p class="m-0">A exclusão foi realizada com sucesso!</p>
                                            <p>Aguarde <b></b> a página vai ser atualizada.</p>
                                        `,
                                        timer: 2000,
                                        timerProgressBar: true,
                                        allowOutsideClick: false,
                                        allowEscapeKey: false,
                                        allowEnterKey: false,
                                        showConfirmButton: false,
                                        showCancelButton: false,
                                        onOpen: () => {
                                            Swal.showLoading()
                                            const b = Swal.getHtmlContainer().querySelector('b')
                                            timerInterval = setInterval(() => {
                                                b.textContent = Swal.getTimerLeft()
                                            }, 100)
                                        },
                                        onClose: () => {
                                            clearInterval(timerInterval)
                                        }
                                    }).then((result) => {
                                        if (result.dismiss === Swal.DismissReason.timer) {
                                            window.location.reload();
                                        }
                                    });
                                break;
                                default:
                                    Swal.fire(
                                        'Atenção!',
                                        response.data.message,
                                        'warning'
                                    );
                                break;
                            }
                        }).catch((error) => {
                            try {
                                Swal.fire(
                                    'Falha',
                                    self.network_erros[error.status]['message'],
                                    'error'
                                );
                            } catch(e) {
                                Swal.fire(
                                    'Falha',
                                    self.network_erros[408]['message'],
                                    'error'
                                );
                            }
                        }).finally(() => {
                            self.bg_loading = false;
                        });
                    }
                });
            }
        },
        async selectTypeUsers(userType) {

            const { value: formValues } = await Swal.fire({
                title: `${userType == 1 ? 'Vendedores' : 'Gerentes'}`,
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                cancelButtonText: 'Cancelar',
                confirmButtonText: 'Buscar',
                html: `
                    <select id="swal-input1" class="swal2-input w-100">
                        ${userType == 1 ? `
                            <option value="1" selected>Ativo</option>
                            <option value="2">Bloqueado</option>
                            <option value="3">Por gerente</option>    
                        ` : `
                            <option value="1" selected>Ativo</option>
                            <option value="2">Bloqueado</option>
                            <option value="3">Por supervisor</option>
                        `}
                    </select>
                `,
                focusConfirm: false,
                preConfirm: () => {
                    return {
                        tipo: document.getElementById('swal-input1').value,
                    }
                },
            });

            if (formValues) {

                let userID = userType == 1 ? 2 : 5;

                if (formValues.tipo == 1) {
                    this.usersListStatus(1, userType);
                } else if (formValues.tipo == 2) {
                    this.usersListStatus(2, userType);
                } else {
                    this.userList(userID);
                }
            }
        }
    },
    watch: {
        type(value) {
            if (value == '') {
                this.search = '';
            } else if (value == 3) {
                this.filterAbsent();
            } else {
                if (this.filter_absent) {
                    this.usersListStatus(value);
                } else {
                    this.search = value == 1 ? 'Ativo' : 'Bloqueado';
                }
            }  
        },
        user_type(value) {
            if (value == 1) {
                this.screen_innerHeight = window.innerHeight - heightAdjust;
            } else {
                this.screen_innerHeight = window.innerHeight - 205;
            }
        },
        search() {
            this.filter_absent = false;
        },
        users() {
            
            this.filter_absent_cancel = false;

            var users = this.users;
            var select = document.querySelector('#virtual-select');

            if (users.length) {

                const options = users.map((item) => {
                    return {
                        label: item.nome,
                        value: item.usuario,
                        description: item.endereco
                    }
                });
                
                select.setOptions(options);
            }
        },
        bg_loading(value) {
            document.getElementsByTagName('html')[0].style.overflow = (value == true ? 'hidden' : 'auto');
        }
    },
    filters: {
        fromNowDate(date) {
            return moment(date).isValid() ? moment(date).locale('pt-br').fromNow() : 'Sem informações';
        },
        formatDate(date) {
            return moment(date).isValid() ? moment(date).tz('America/Recife').format('DD/MM/Y HH:mm:ss') : 'Sem informações';
        },
        formatCoin(n, c, d, t, s, i, j) {
            c = isNaN(c = Math.abs(c)) ? 2 : c, d = d == undefined ? "," : d, t = t == undefined ? "." : t, s = n < 0 ? "-" : "", i = parseInt(n = Math.abs(+n || 0).toFixed(c)) + "", j = (j = i.length) > 3 ? j % 3 : 0;
            return s + (j ? i.substr(0, j) + t : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t) + (c ? d + Math.abs(n - i).toFixed(c).slice(2) : "");
		}
    },
    computed: {
        listUsers() {
            return this.users.filter((item) => {
                return this.removeAccents(item.nome).toLowerCase().trim().includes(this.removeAccents(this.search).toLowerCase().trim()) || this.removeAccents(item.endereco).toLowerCase().trim().includes(this.removeAccents(this.search).toLowerCase().trim()) || item.usuario.toLowerCase().trim().includes(this.removeAccents(this.search).toLowerCase().trim()) || item.situacao.includes(this.search)
            });
        }
    },
    mounted() {

        const self = this;

        window.VirtualSelect.init({
            ele: '#virtual-select',
            search: true,
            placeholder: 'Selecione um usuário',
            noOptionsText: 'Nenhuma opção disponível',
            noSearchResultsText: 'Nenhum resultado encontrado',
            searchPlaceholderText: 'Buscar usuário...',
            hasOptionDescription: true,
        });

        if (document.querySelector('#virtual-select')) {
            document.querySelector('#virtual-select').addEventListener('change', function() {
                self.search = this.value;
            });
        }
    },
    created() {

        try {
            
            const {pipe} = JSON.parse(window.localStorage.getItem('auth'));

            this.pipe = pipe;

            if (this.pipe == 2) {
                this.usersList(1);
            }
        } 
        catch(e) 
        {
            this.pipe = 0;
        }
        
        window.addEventListener('resize', () => {
            this.platform = this.isMobile();
            this.screen_innerHeight = window.innerHeight - (this.platform == 'mobile' ? heightAdjustMobile : heightAdjustDesktop);
        });
    }
}
</script>

<style scoped>
.main {
    margin-top: 80px;
}
.d-contents {
    display: contents!important;
}
#dropdown-menu-account {
    border-top-right-radius: 0!important;
    border-bottom-right-radius: 0!important;
}
.loading {
    width: 100%;
    height: 100%;
    top: 70px;
    position: fixed;
    z-index: 1031;
    display: flex;
    background-color: #333;
}
.loading .justify-content-center {
    width: 100%;
    align-items: center;
}
.collection-cluster {
    overflow-x: auto;
}
.collection-cluster.desktop::-webkit-scrollbar {
  width: 9px;
  height: 9px;
}
.collection-cluster.desktop::-webkit-scrollbar-button {
  width: 0px;
  height: 0px;
}
.collection-cluster.desktop::-webkit-scrollbar-thumb {
  background: #e1e1e1;
  border: 0px none #ffffff;
  border-radius: .25rem;
}
.collection-cluster.desktop::-webkit-scrollbar-thumb:hover {
  background: #ffffff;
}
.collection-cluster.desktop::-webkit-scrollbar-thumb:active {
  background: #ddd;
}
.collection-cluster.desktop::-webkit-scrollbar-track {
  background: #666666;
  border: 0px none #ffffff;
  border-radius: .25rem;
}
.collection-cluster.desktop::-webkit-scrollbar-track:hover {
  background: #666666;
}
.collection-cluster.desktop::-webkit-scrollbar-track:active {
  background: #333333;
}
.collection-cluster.desktop::-webkit-scrollbar-corner {
  background: transparent;
}
.btn-group.w-100.text-nowrap {
    overflow-y: auto;
}
.dropdown.d-flex #dropdown-menu-salesman {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}
.dropdown.d-flex #dropdown-menu-manager {
    border-radius: 0;
}
.btn-group .dropdown.d-flex {
    display: flex;
    -webkit-box-flex: 1;
    flex: 1 1 auto;
}
.btn-group .dropdown.d-flex .dropdown-toggle {
    z-index: auto!important;
    position: relative;
    -ms-flex: 1 1 auto;
    -webkit-box-flex: 1;
    flex: 1 1 auto;
}
</style>
